import React from "react";

function Alert(props) {
  const { alert } = props;

  return (
    <>
      {alert && (
        <div
          className={
            alert["status"] && alert["status"] === 400
              ? "alert alert-error"
              : "alert alert-success"
          }
        >
          {alert && alert["title"] && alert["title"]}
          {alert &&
            alert["email_address"] &&
            alert["email_address"] + " added!"}
          {alert && alert["message"] && alert["message"]}
        </div>
      )}
    </>
  );
}

export default Alert;
