import React from "react";

const SingleRecipeLabel = (props) => {
  const { item, handleDeleteIng } = props;

  return (
    <div key={JSON.stringify(item.option)} className="usda-row-wrapper">
      <div className="usda-table-group">{item.option["Name"]}</div>
      <div className="usda-table-group">{item.serving}</div>
      <div className="usda-table-group">{item.option["Grams_Desc1"]}</div>

      {handleDeleteIng && (
        <div className="usda-table-sub-amount">
          <div
            id={JSON.stringify(item)}
            title={item.option["Name"]}
            onClick={handleDeleteIng}
            className="ing-delete"
          >
            X
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleRecipeLabel;
