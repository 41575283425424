import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import Zone from "./Zone";

const BzMain = () => {
  const appContext = useContext(AppContext);

  // useEffect(() => {
  //   if (appContext.zones) {
  //     console.log(appContext.zones);
  //   }
  // }, [appContext.zones]);

  return (
    <div className="lesson-wrapper">
      {/* <div className="calorie-calculator-selector-wrapper">
        <a href="#longevity-blue-zones" id="longevity-blue-zones">
          <h1 className="">Longevity Blue Zones</h1>
        </a>
      </div> */}
      <div className="zone-info-wrapper">
        {appContext.zones.map((zone) => {
          return <Zone zone={zone} />;
        })}
      </div>
    </div>
  );
};

export default BzMain;
