import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { validateEmail } from "../../lib/functions";
import Loader from "./Loader";
import Alert from "./Alert";

const NewsletterForm = () => {
  const appContext = useContext(AppContext);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [redirect, setRedirect] = useState();
  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewsletterSignup = () => {
    setIsLoading(true);
    fetch(`${appContext.URL_2}/mailchimp-signup`, {
      method: "POST",
      body: JSON.stringify({
        formData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data === "string") {
          const mcMessage = JSON.parse(data);
          setIsLoading(false);
          setShowAlert(mcMessage);
          setTimeout(() => {
            setShowAlert(false);
            setRedirect("/calorie-calculator");
          }, [2000]);
        } else if (typeof data === "object") {
          setIsLoading(false);
          setShowAlert(data);
          setTimeout(() => {
            setShowAlert(false);
            setRedirect("/");
          }, [2000]);
        }
      });
  };

  useEffect(() => {
    if (formData && formData.email) {
      const emailValidity = validateEmail(formData.email);
      setIsValidEmail(emailValidity);
    }
  }, [formData]);

  return (
    <div className="lesson-wrapper">
      <div className="calorie-calculator-selector-wrapper">
        <a href="#newsletter-signup" id="newsletter-signup">
          <h1 className="">Newsletter Signup</h1>
        </a>
      </div>
      <div className="calorie-calculator-items-wrapper">
        {redirect && <Redirect to={redirect} />}
        {showAlert && <Alert alert={showAlert} />}
        <div className="recipe-form-wrapper">
          <div className="newsletter-form">
            <div className="apply-input-wrapper">
              <input
                name="email"
                type="email"
                placeholder="Email"
                className="calculator-input apply-form-input"
                onChange={handleInputChange}
                // value={formData && formData.email ? formData.email : }
              />
              <div
                className={`apply-form-label apply-form-label-${isValidEmail}`}
              >
                {isValidEmail ? "✓" : "Valid email required"}
              </div>
            </div>
          </div>

          {isLoading && (
            <div className={`to-form-button to-form-button-true`}>
              <Loader />
            </div>
          )}
          {!isLoading && (
            <button
              disabled={!(formData && formData.email && isValidEmail)}
              className={`to-form-button to-form-button-${
                formData && formData.email && isValidEmail
              }`}
              onClick={handleNewsletterSignup}
            >
              Join Newsletter
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;
