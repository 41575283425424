import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";

const BzCalculator = (props) => {
  const { recipeIngredients, calculatedRecipeObj } = props;
  const appContext = useContext(AppContext);
  const [bzResult, setBzResult] = useState();

  useEffect(() => {
    if (recipeIngredients) {
      let bzCalculation = {};
      recipeIngredients.forEach((item) => {
        appContext.zones.forEach((z) => {
          let ingArray = [];
          if (
            Object.keys(z.ingredients).includes(
              item.option["Name"].replace(" ", "_")
            ) ||
            Object.keys(z.ingredients).includes(
              item.option["Type"].replace(" ", "_")
            )
          ) {
            ingArray.push(item);
          }
          if (bzCalculation[z["city"]]) {
            bzCalculation[z["city"]] =
              bzCalculation[z["city"]].concat(ingArray);
          } else {
            bzCalculation[z["city"]] = ingArray;
          }
        });
      });
      setBzResult(bzCalculation);
    }
  }, [recipeIngredients]);

  return (
    <div className="lesson-wrapper">
      <div className="calorie-calculator-selector-wrapper">
        <a href="#blue-zones-calculator" id="blue-zones-calculator">
          <h1 className="">Blue Zone Calculator</h1>
        </a>
      </div>
      <h3 className="calorie-calculator-selector-wrapper">
        Discover how the recipe compares to the top longevity foods in the Blue
        Zones diets
      </h3>
      {(!recipeIngredients || !calculatedRecipeObj) && (
        <h2>Add Ingredients To Your Recipe</h2>
      )}
      {bzResult && calculatedRecipeObj && (
        <div className="all-zones-wrapper">
          <h3>The recipe has:</h3>
          {Object.keys(bzResult).map((item) => {
            let totalPercent = 0;
            return (
              <div className="zone-wrapper">
                <span className="zone-bold">{bzResult[item].length} </span>
                {bzResult[item].length === 1
                  ? "ingredient"
                  : "ingredients"}{" "}
                from the <span className="zone-bold">{item} </span>
                diet and based on serving sizes in grams is{" "}
                {bzResult[item].map((ing) => {
                  totalPercent +=
                    ((parseFloat(ing.option["Grams_1"]) *
                      parseFloat(ing.serving)) /
                      calculatedRecipeObj["Grams_1"]) *
                    100;
                  return (
                    <>
                      {(
                        ((parseFloat(ing.option["Grams_1"]) *
                          parseFloat(ing.serving)) /
                          calculatedRecipeObj["Grams_1"]) *
                        100
                      ).toFixed(1)}
                      % {ing.option["Name"]}
                      {bzResult[item].length === 1 ? " " : ", "}
                    </>
                  );
                })}
                {bzResult[item].length === 0 ? " " : "and  "}{" "}
                <span className="zone-bold">
                  {totalPercent.toFixed(1)}% {item}n{" "}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BzCalculator;
