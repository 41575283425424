import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../../context/AppContext";

const HomeRecipes = () => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    console.log(appContext.homeRecipes);
  }, [appContext.homeRecipes]);

  return (
    <div className="lesson-wrapper-col">
      <h1>Home Recipes</h1>
      <h2>Breakfast</h2>
      {appContext.homeRecipes &&
        appContext.homeRecipes.map((item) => {
          if (item["meal"] === "breakfast") {
            return (
              <>
                <Link
                  to={`home-recipes/${item["name"]
                    .replace(/ /g, "-")
                    .replace(/, /g, "")
                    .toLowerCase()}`}
                >
                  <h3>{item["name"]}</h3>
                </Link>
                <div>{item["description"]}</div>
              </>
            );
          }
        })}

      <h2>Lunch</h2>
      {appContext.homeRecipes &&
        appContext.homeRecipes.map((item) => {
          if (item["meal"] === "lunch") {
            return (
              <>
                <Link
                  to={`home-recipes/${item["name"]
                    .replace(/ /g, "-")
                    .replace(/, /g, "")
                    .toLowerCase()}`}
                >
                  <h3>{item["name"]}</h3>
                </Link>
                <div>{item["description"]}</div>
              </>
            );
          }
        })}

      <h2>Dinner</h2>
      {appContext.homeRecipes &&
        appContext.homeRecipes.map((item) => {
          if (item["meal"] === "dinner") {
            return (
              <>
                <Link
                  to={`home-recipes/${item["name"]
                    .replace(/ /g, "-")
                    .replace(/, /g, "")
                    .toLowerCase()}`}
                >
                  <h3>{item["name"]}</h3>
                </Link>
                <div>{item["description"]}</div>
              </>
            );
          }
        })}

      <h2>Snacks</h2>
      {appContext.homeRecipes &&
        appContext.homeRecipes.map((item) => {
          if (item["meal"] === "snacks") {
            return (
              <>
                <Link
                  to={`home-recipes/${item["name"]
                    .replace(/ /g, "-")
                    .replace(/, /g, "")
                    .toLowerCase()}`}
                >
                  <h3>{item["name"]}</h3>
                </Link>
                <div>{item["description"]}</div>
              </>
            );
          }
        })}
    </div>
  );
};

export default HomeRecipes;
