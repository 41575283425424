import React, { useEffect, useState } from "react";

const IngredientsForm = (props) => {
  const {
    recipeIngredients,
    setRecipeIngredients,
    usdaFoodCategories,
    foodCategories,
    selectedOption,
    setSelectedOption,
    unselectedOptions,
    setUnselectedOptions,
  } = props;
  const [selectedServing, setSelectedServing] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (unselectedOptions) {
      const newArray = unselectedOptions.filter((item) => {
        return selectedCategory === item["USDA_Cat"];
      });
      setSelectedOption(newArray[0]);
    }
  }, [recipeIngredients, selectedCategory]);

  useEffect(() => {
    if (usdaFoodCategories) {
      const sortedCategories = Object.keys(usdaFoodCategories).sort();
      setSelectedCategory(sortedCategories[0]);
    }
  }, [usdaFoodCategories]);

  const handleAddClicked = () => {
    if (selectedOption && selectedServing) {
      setRecipeIngredients((prevState) => {
        if (prevState) {
          return [
            ...prevState,
            { option: selectedOption, serving: selectedServing },
          ];
        } else return [{ option: selectedOption, serving: selectedServing }];
      });

      const newArray = unselectedOptions.filter((item) => {
        return item["Name"] !== selectedOption["Name"];
      });
      setUnselectedOptions(newArray);
    }
  };

  return (
    <>
      <div className="calorie-calculator-selector-wrapper">
        <a
          href={`#choose-ingredients`}
          className="calorie-calculator-arrow-wrapper"
        >
          <h1
            id={`choose-ingredients`}
            className="calorie-calculator-selector-wrapper"
          >
            Choose Ingredients
          </h1>
        </a>
      </div>
      {/* <div className="calorie-calculator-promo-text">
        <h2>Choose ingredients and serving sizes.</h2>
      </div> */}
      <div className="calorie-calculator-selector-wrapper">
        <div>
          <select
            name="food-category"
            id="food-category"
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="calculator-input calculator-ingredient"
            value={selectedCategory}
          >
            {usdaFoodCategories &&
              Object.keys(usdaFoodCategories).map((category) => {
                return (
                  <option key={category} value={category}>
                    {category}
                  </option>
                );
              })}
          </select>
          <div className="calorie-calculator-input-label">category</div>
        </div>
        <div>
          <select
            name="ing"
            id="ing"
            onChange={(e) => setSelectedOption(JSON.parse(e.target.value))}
            className="calculator-input calculator-ingredient"
          >
            {unselectedOptions &&
              unselectedOptions.map((item, i) => {
                if (selectedCategory === item["USDA_Cat"]) {
                  return (
                    <option key={item["Name"]} value={JSON.stringify(item)}>
                      {item["Name"]}
                    </option>
                  );
                }
              })}
          </select>
          <div className="calorie-calculator-input-label">
            {selectedOption && selectedOption["Grams_Desc1"]}
          </div>
        </div>
        <div>
          <select
            name="servSize"
            id="servSize"
            value={selectedServing}
            onChange={(e) => setSelectedServing(JSON.parse(e.target.value))}
            className="calculator-input calculator-serving"
          >
            {[...Array(81)].map((item, i) => {
              return <option value={i / 4}>{i / 4}</option>;
            })}
          </select>
          <div className="calorie-calculator-input-label">servings</div>
        </div>
        <div>
          <div className="calorie-calculator-show-selected">
            <div className="calculator-select calculator-ingredient">
              {selectedOption && selectedOption["Name"]}
            </div>
            <div className="calculator-select calculator-serving">
              {selectedServing && selectedServing}
            </div>
          </div>
          <div className="calorie-calculator-input-label">
            do you want to add this item?
          </div>
        </div>
        <div onClick={handleAddClicked} className="lesson-list-button">
          Add
        </div>
      </div>
    </>
  );
};

export default IngredientsForm;
