import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { lessonsObject } from "../../lib/lib";
import AppContext from "../../context/AppContext";
// import { fruits } from "../../images/fruits.jpg";

const ArticlesHome = () => {
  const appContext = useContext(AppContext);
  //   add lesson routes to lessonsArray

  // const imagesObj = { fruits: fruits };
  // console.log(imagesObj["fruits"]);

  const lessonsArray = [
    {
      icon: "fab fa-python",
      slug: `fruits`,
    },
    {
      icon: "fab fa-github",
      slug: `beans`,
    },
    // {
    //   icon: "fab fa-raspberry-pi",
    //   slug: `raspberry-pi`,
    // },
    // {
    //   icon: "fab fa-html5",
    //   slug: `html-basics`,
    // },
    // {
    //   icon: "fab fa-css3",
    //   slug: `css-basics`,
    // },
    // {
    //   icon: "fab fa-github",
    //   slug: `github-basics`,
    // },
    // {
    //   icon: "fab fa-js",
    //   slug: `javascript-basics`,
    // },
    // {
    //   icon: "fab fa-node",
    //   slug: `nodejs-basics`,
    // },
    // {
    //   icon: "fab fa-react",
    //   slug: `react-basics`,
    // },
  ];

  const kitchenGadgetsArray = [
    // {
    //   icon: "fab fa-python",
    //   slug: `slow_cookers`,
    // },
    {
      icon: "fab fa-python",
      slug: `kitchen-gadgets`,
    },
    // {
    //   icon: "fab fa-github",
    //   slug: `cookware-and-bakeware`,
    // },
    // {
    //   icon: "fab fa-raspberry-pi",
    //   slug: `toasters`,
    // },
    // {
    //   icon: "fab fa-html5",
    //   slug: `blenders`,
    // },
    // {
    //   icon: "fab fa-css3",
    //   slug: `juicers`,
    // },
    // {
    //   icon: "fab fa-github",
    //   slug: `microwave-ovens`,
    // },
    // {
    //   icon: "fab fa-js",
    //   slug: `food-processors`,
    // },
    // {
    //   icon: "fab fa-node",
    //   slug: `friers`,
    // },
    // {
    //   icon: "fab fa-react",
    //   slug: `react-basics`,
    // },
  ];

  const handleLoadData = () => {
    // // LOADS MONGODB WITH SHEET DATA
    const docId = process.env.REACT_APP_DOCID_NUTR_INFO;

    fetch(appContext.URL_2 + `/get-google-sheet/${docId}/A1:BB`)
      .then((response) => response.json())
      .then((data) => {
        // setTutorialFromDocs(data);
        console.log(data);
      });

    ////////////////////////////////////////
  };
  return (
    <>
      <Link to={`/`} className="lesson-home-button">
        {"<<< " + "Home"}
      </Link>

      <h2 className="lesson-title">Learn how to work with food at home</h2>
      <div className="articles-list">
        {lessonsArray.map((lesson) => {
          return (
            <Link to={`/${lesson["slug"]}`} className="article-prev-card">
              <h2 className="article-prev-card-title">
                {(
                  lesson["slug"].charAt(0).toUpperCase() +
                  lesson["slug"].slice(1)
                ).replace(/-/g, " ")}
              </h2>
              <img
                src={require(`../../images/${lesson["slug"].replace(
                  /-/g,
                  "_"
                )}.png`)}
                className="article-prev-card-image"
              />
              <div className="article-prev-card-button">{">>>"}</div>
            </Link>
          );
        })}

        {/* // // LOADS MONGODB WITH SHEET DATA */}
        {/* <button onClick={handleLoadData}>Load Data</button> */}
        {/* // //  */}

        <div>
          Icons made by{" "}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </div>

      {/* <h2 className="lesson-title">Kitchen Gadgets</h2>
      <div className="lesson-list">
        {kitchenGadgetsArray.map((item) => {
          return (
            <Link
              to={`/${item["slug"]}`}
              className="item-list-button font-size-24"
            >
              {(
                item["slug"].charAt(0).toUpperCase() + item["slug"].slice(1)
              ).replace(/-/g, " ")}
            </Link>
          );
        })}
      </div> */}
    </>
  );
};

export default ArticlesHome;
