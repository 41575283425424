import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import parse from "html-react-parser";
import axios from "axios";
import OneRecipe from "./OneRecipe";

const Recipes = (props) => {
  const { topic } = props;
  const appContext = useContext(AppContext);
  const [recipes, setRecipes] = useState([]);

  const getRecipes = (topic) => {
    fetch(`${appContext.URL_2}/get-recipes/${topic}`)
      .then((response) => response.json())
      .then((data) => setRecipes(data));
  };

  useEffect(() => {
    if (topic) {
      getRecipes(topic);
    }
  }, [topic]);

  useEffect(() => {
    if (recipes) {
      console.log(recipes);
    }
  }, [recipes]);

  return (
    <div className="recipes-main-wrapper">
      <h2 id={`${topic.replace(/_/g, "-")}-recipes`}>
        <a
          href={`#${topic.replace(/_/g, "-")}-recipes`}
          className="capitalize-first"
        >
          {topic.replace(/_/g, " ")} recipes
        </a>
      </h2>
      {recipes.map((item) => {
        return <OneRecipe item={item} />;
      })}
    </div>
  );
};

export default Recipes;
