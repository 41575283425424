import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import IngCard from "../Lib/IngCard";

const HNTDCalculator = (props) => {
  const appContext = useContext(AppContext);
  const { recipeIngredients } = props;
  const [hntdFoodCategories, setHNTDFoodCategories] = useState();
  const [numberHNTDIng, setNumberHNTDIng] = useState(0);
  const [notHNTDIng, setNotHNTDIng] = useState(0);
  const [numberHNTDServ, setNumberHNTDServ] = useState(0);
  const [notHNTDServ, setNotHNTDServ] = useState(0);
  const [otherFoodCategories, setOtherFoodCategories] = useState();

  useEffect(() => {
    if (appContext.foodCategories && recipeIngredients) {
      const hntdObj = {
        beans: 3,
        berries: 1,
        fruits: 3,
        cruciferous: 1,
        greens: 2,
        vegetables: 2,
        seeds: 1,
        nuts: 1,
        spices: 1,
        "whole grains": 3,
        beverages: 5,
        exercise: 1,
      };
      const newHNTDObj = {};
      const newOtherObj = {};
      let newHNTDNumber = 0;
      let newNotNumber = 0;
      let newHNTDServ = 0;
      let newNotServ = 0;
      Object.keys(appContext.foodCategories).forEach((item) => {
        if (Object.keys(hntdObj).includes(item)) {
          newHNTDObj[item] =
            appContext.foodCategories[item].toString() +
            " / " +
            hntdObj[item].toString();
          if (parseFloat(appContext.foodCategories[item]) > 0) {
            newHNTDServ += appContext.foodCategories[item];
            recipeIngredients.forEach((thing) => {
              if (item === thing.option["Type"]) {
                newHNTDNumber += 1;
              }
            });
          }
        } else if (parseFloat(appContext.foodCategories[item]) > 0) {
          newOtherObj[item] = appContext.foodCategories[item];
          if (parseFloat(appContext.foodCategories[item]) > 0) {
            newNotServ += appContext.foodCategories[item];
            // recipeIngredients.forEach((thing) => {
            //   console.log(item);
            //   if (item !== thing.option["Type"]) {
            //     newNotNumber += 1;
            //   }
            // });
          }
        }
      });
      newNotNumber = recipeIngredients.length - newHNTDNumber;
      newHNTDObj["exercise"] = "!";

      setHNTDFoodCategories(newHNTDObj);
      setOtherFoodCategories(newOtherObj);
      setNumberHNTDIng(newHNTDNumber);
      setNotHNTDIng(newNotNumber);
      setNumberHNTDServ(newHNTDServ);
      setNotHNTDServ(newNotServ);
    }
  }, [appContext.foodCategories, recipeIngredients]);

  return (
    <div className="lesson-wrapper">
      <div className="calorie-calculator-selector-wrapper">
        <a href="#how-not-to-die-calculator" id="how-not-to-die-calculator">
          <h1 className="">How Not To Die Calculator</h1>
        </a>
      </div>
      <h3 className="calorie-calculator-selector-wrapper">
        Discover how the recipe compares to the guidelines in the How Not To Die
        Guide
      </h3>
      {!recipeIngredients && <h2>Add Ingredients To Your Recipe</h2>}
      {recipeIngredients && appContext.foodCategories && (
        <>
          <h3>
            The recipe has{" "}
            <span className="hntd-bold">{numberHNTDIng} ingredients</span> for a
            combined{" "}
            <span className="hntd-bold">{numberHNTDServ} servings</span> from
            Dr. Greger's Daily Dozen
          </h3>

          {hntdFoodCategories && (
            <div className="calorie-calculator-items-wrapper">
              <div className="hntd-selected-categories">
                {Object.keys(hntdFoodCategories)
                  .sort()
                  .map((key) => {
                    return (
                      <IngCard name={key} serving={hntdFoodCategories[key]} />
                    );
                  })}
              </div>
            </div>
          )}

          <h3>
            The recipe has{" "}
            <span className="hntd-bold">{notHNTDIng} ingredients</span> for a
            combined <span className="hntd-bold">{notHNTDServ} servings</span>{" "}
            that are <span className="hntd-bold">not</span> in the Daily Dozen
          </h3>

          {otherFoodCategories && (
            <div className="calorie-calculator-items-wrapper">
              <div className="hntd-selected-categories">
                {Object.keys(otherFoodCategories)
                  .sort()
                  .map((key) => {
                    return (
                      <div key={key} className="hntd-ing-item-wrapper">
                        <div className="hntd-ing-item-number">
                          {otherFoodCategories[key]}
                        </div>
                        <div className="hntd-ing-item-serving">
                          {"servings"}
                        </div>
                        <div className="hntd-ing-item-name">{key}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HNTDCalculator;
