import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppContext from "../../context/AppContext";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../components/Main/CodeBlock";
import { getSlug } from "../../lib/functions";

const PythonBasics = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [pyLessons, setPyLessons] = useState([]);
  const [linkLesson, setLinkLesson] = useState();
  const [readMe, setReadMe] = useState();

  useEffect(() => {
    setLinkLesson(location.pathname);
    fetch(appContext.URL_2 + location.pathname.replace(/-/g, "_"))
      .then((response) => response.json())
      .then((data) => {
        setPyLessons(data);
      });

    fetch(
      appContext.URL_2 +
        `/get-lesson${location.pathname.replace(/-/g, "_")}/README.md/`
    )
      .then((response) => response.json())
      .then((data) => {
        setReadMe(data);
      });
  }, []);

  // useEffect(() => {
  //   const dbIngredients = getMongoDBIngredients(
  //     appContext.URL_2 + `/get-mongo-ingredients`
  //   );
  //   dbIngredients
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // setReadMe(data);
  //       setMongoNutrIngr(data);
  //     });
  // }, []);

  useEffect(() => {
    if (appContext.mongoNutrIngr) {
      console.log(appContext.mongoNutrIngr);
    }
  }, [appContext.mongoNutrIngr]);

  // const getSlug = (foodName) => {
  //   console.log(appContext.mongoIngr);
  //   const ingArray = appContext.mongoIngr.filter((item) => {
  //     // console.log(item["Name"], "foodName: " + foodName);
  //     return item["name"] === foodName;
  //   });
  //   console.log(foodName);
  //   console.log(ingArray[0]);

  //   if (ingArray[0]) {
  //     return ingArray[0]["slug"];
  //   } else {
  //     return `${foodName}-nutrition-info`;
  //   }
  // };

  return (
    <div>
      <Link to={`/articles`} className="lesson-home-button">
        {"<<< " + "Articles"}
      </Link>
      {linkLesson && (
        <h1 className="lesson-title">
          {linkLesson.replace(/\//g, "").replace(/-/g, " ")}
        </h1>
      )}
      <div className="articles-list">
        {readMe && (
          <ReactMarkdown
            source={readMe}
            renderers={{ code: CodeBlock }}
            className="lesson-wrapper-col"
          />
        )}
        {appContext.mongoNutrIngr &&
          pyLessons !== [] &&
          pyLessons.map((lesson) => {
            if (lesson !== "README.md" && lesson.includes(".md")) {
              return (
                <Link
                  to={{
                    pathname: `${linkLesson}/${lesson
                      .slice(0, -3)
                      .replace(/_/g, "-")}/${getSlug(
                      lesson.slice(0, -3).replace(/_/g, " "),
                      appContext.mongoIngr
                    )}`,
                    state: {
                      lesson_file: lesson,
                      lessons: pyLessons,
                      lesson_type: linkLesson,
                    },
                  }}
                  className="article-prev-card"
                >
                  <div className="article-prev-card-subtitle">
                    {lesson.slice(0, -3).replace(/_/g, " ")}
                  </div>
                  <h2 className="article-prev-card-title">
                    {getSlug(
                      lesson.slice(0, -3).replace(/_/g, " "),
                      appContext.mongoIngr
                    ).replace(/-/g, " ")}
                  </h2>
                  <div className="article-prev-card-button">{">>>"}</div>
                </Link>
              );
            }
          })}
      </div>
    </div>
  );
};

export default PythonBasics;
