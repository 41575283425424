import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import stockProfilePhoto from "../../images/profile_photo.png";
import SingleRecipeLabel from "./SingleRecipeLabel";

const OneRecipe = (props) => {
  const appContext = useContext(AppContext);
  const { item, nutritionInfoIng, parent } = props;
  const [recipeAuthor, setRecipeAuthor] = useState();
  const [parsedIngredients, setParsedIngredients] = useState();
  //   nuts
  //   whole grains
  //   herbs
  //   coconut oil
  //   butter
  //   vinegar
  //   water
  //   sugar

  const getUserProfilePhoto = async (id) => {
    return await fetch(`${appContext.URL_2}/get-user-profile-by-id/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setRecipeAuthor(data);
      });
  };

  useEffect(() => {
    if (parent === "recipe-form") {
      getUserProfilePhoto(item["user_id"]);
    }
  }, [item]);

  useEffect(() => {
    if (nutritionInfoIng) {
      console.log(nutritionInfoIng);
    }
  }, [nutritionInfoIng]);

  const getIngServSize = (ingName) => {
    let ingNutr = "not available";
    if (nutritionInfoIng) {
      console.log(nutritionInfoIng);
      ingNutr = nutritionInfoIng.filter((item) => {
        return item.option["Name"] === ingName;
      });
      console.log(ingNutr);
      ingNutr = ingNutr[0].option["Grams_Desc1"];
    }
    return ingNutr;
  };

  return (
    <>
      <div key={item["_id"]} className="recipe-wrapper">
        <h1>{item["name"]}</h1>

        <div className="recipe-intro-wrapper">
          <div className="recipe-intro">
            A <span className="recipe-bold">{item["meal"]}</span> that{" "}
            <span className="recipe-bold">serves {item["num_served"]}</span>. It
            takes{" "}
            <span className="recipe-bold">{item["active_prep_time"]} min</span>{" "}
            of active prep and{" "}
            <span className="recipe-bold">{item["passive_prep_time"]} min</span>{" "}
            of passive prep.
          </div>
        </div>

        <div className="ingredients-wrapper-col">
          <h3 className="ingredients-title">Description:</h3>
          <div className="font-size-16">{item["description"]}</div>
        </div>

        <div className="ingredients-wrapper-col">
          <div className="ingredients-title">Ingredients:</div>
          <div className="calorie-calculator-items-wrapper">
            <div className="selected-items">
              <div className="ing-item-wrapper">
                <div className="usda-table-title usda-table-blue-bkgd-color">
                  Ingredient
                </div>
                <div className="usda-table-title">Servings</div>
                <div className="usda-table-title">Serving Size</div>
              </div>
              {Object.keys(item["ingredients"]).map(function (key, index) {
                return (
                  <div key={key} className="usda-row-wrapper">
                    <div className="usda-table-group">
                      {key.replace(/_/g, " ")}
                    </div>
                    <div className="usda-table-group">
                      {item["ingredients"][key]}
                    </div>
                    <div className="usda-table-group">
                      <div>{getIngServSize(key.replace(/_/g, " "))}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* {appContext.foodCategories && (
              <div className="selected-categories">
                {Object.keys(appContext.foodCategories).map((key) => {
                  return (
                    <div key={key} className="ing-item-wrapper">
                      <div className="ing-item-number">
                        {appContext.foodCategories[key]}
                      </div>
                      <div className="ing-item-serving">{"serving"}</div>
                      <div className="ing-item-name">{key}</div>
                    </div>
                  );
                })}
              </div>
            )} */}
          </div>
        </div>
        <div className="ingredients-wrapper-col">
          <h3 className="ingredients-title">Instructions:</h3>
          <div className="font-size-16">{item["instructions"]}</div>
        </div>

        <div className="ingredients-wrapper-col">
          <a href={item["link"]}>
            <div className="recipe-user">
              {parent === "recipe-form" && (
                <>
                  <img
                    src={
                      item["user_id"] !== "guest"
                        ? recipeAuthor["photo"]
                        : stockProfilePhoto
                    }
                    className="recipe-user-profile-photo"
                  />
                  <div>
                    {item["user_id"] !== "guest"
                      ? recipeAuthor["name"]
                      : "Guest"}
                  </div>
                </>
              )}

              {parent === "one-home-recipe" && (
                <>
                  <img
                    src={item.author["photo"]}
                    className="recipe-user-profile-photo"
                  />
                  <div>{item.author["user_name"]}</div>
                </>
              )}
            </div>
            <h3 className="tutorial-text-link">
              Learn more about{" "}
              {/* {item["user_id"] !== "guest" ? recipeAuthor["name"] : "Guest"}'s{" "} */}
              {item["name"]} recipe
            </h3>
          </a>
        </div>
      </div>
    </>
  );
};

export default OneRecipe;
