import React, { useEffect, useState } from "react";

const CustomTooltip = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    return (
      <div className={type === "bar" ? "tooltip" : ""}>
        <p className="legend-desc">
          {payload[0].payload.name
            .split("_")
            .filter((item) => {
              return item !== "_";
            })
            .filter((item) => {
              return !item.includes("(");
            })
            .join(" ")}
        </p>
        {type === "pie" && (
          <p className="legend-label">{`${payload[0].value.toFixed(1)}`}</p>
        )}
        {type === "bar" && (
          <p className="label">{`${payload[0].value} % DRV`}</p>
        )}
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
