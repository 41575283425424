import React from "react";
import SingleRecipeLabel from "./SingleRecipeLabel";

const RecipeAndLabel = (props) => {
  const { recipeIngredients, handleDeleteIng, foodCategories } = props;

  return (
    <div className="calorie-calculator-items-wrapper">
      <div className="selected-items">
        <div className="ing-item-wrapper">
          <div className="usda-table-title usda-table-blue-bkgd-color">
            Ingredient
          </div>
          <div className="usda-table-title">Servings</div>
          <div className="usda-table-title">Serving Size</div>
          <div className="usda-table-title">Delete</div>
        </div>
        {recipeIngredients.map((item) => {
          return (
            <SingleRecipeLabel item={item} handleDeleteIng={handleDeleteIng} />
          );
        })}
      </div>
      {/* <div className="selected-categories">
        {Object.keys(foodCategories).map((key) => {
          return (
            <div key={key} className="ing-item-wrapper">
              <div className="ing-item-number">{foodCategories[key]}</div>
              <div className="ing-item-serving">{"serving"}</div>
              <div className="ing-item-name">{key}</div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default RecipeAndLabel;
