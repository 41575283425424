import React from "react";
import ExampleItem from "./ExampleItem";

const AmazonExamples = (props) => {
  const { topic } = props;
  return (
    <div className="">
      {topic !== "food_calculator" && (
        <>
          <h2 id={`examples-of-${topic.replace(/_/g, "-")}`}>
            <a href={`#examples-of-${topic.replace(/_/g, "-")}`}>
              Examples of {topic.replace(/_/g, " ")}s
            </a>
          </h2>
          <h2 className="">on amazon</h2>
        </>
      )}
      <ExampleItem componentLocation="main" topic={props.topic} />
    </div>
  );
};

export default AmazonExamples;
