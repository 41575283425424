import React from "react";

const IngCard = (props) => {
  const { serving, name } = props;

  return (
    <div key={name} className="hntd-ing-item-wrapper">
      <div className="hntd-ing-item-number">{serving}</div>
      <div className="hntd-ing-item-serving">{"servings"}</div>
      <div className="hntd-ing-item-name">{name}</div>
    </div>
  );
};

export default IngCard;
