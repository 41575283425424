import React from "react";

const AppContext = React.createContext({
  URL: "",
  URL_2: "",
  // URL_3: "",
  user: "",
  setUser: "",
  userProfile: "",
  setUserProfile: "",
  krogerAuth: "",
  mongoNutrIngr: "",
  mongoIngr: "",
  zones: "",
  setZones: "",
  tbInfo: "",
  setTBinfo: "",
  homeRecipes: "",
  setHomeRecipes: "",
  usdaFoodCategories: "",
  setUsdaFoodCategories: "",
  foodCategories: "",
  setFoodCategories: "",
  usdaGuidelinesData: "",
  nutrientDenseOptions: "",
});

export default AppContext;
