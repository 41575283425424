import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { exampleItems, shuffleArray } from "../../lib/lib";
import buyButton from "../../images/AmazonButton.png";

const ExampleItem = (props) => {
  const { componentLocation, topic } = props;
  const [arrayForDisplay, setArrayForDisplay] = useState([]);

  useEffect(() => {
    if (topic) {
      let shuffledArray = [];
      if (exampleItems[topic]) {
        shuffledArray = shuffleArray(exampleItems[topic]);
      } else if (!exampleItems[topic]) {
        shuffledArray = shuffleArray(exampleItems["default"]);
      }
      setArrayForDisplay(shuffledArray);
    }
  }, [topic]);

  return (
    <div className={`cards-wrapper-${componentLocation}`}>
      <Helmet>
        <title>Recipe Food Club | Discover Recipes</title>
        <meta
          name="description"
          content={`A food club for recipes. Healthy recipes and helpful tools for the kitchen.`}
        />
      </Helmet>
      <div className={`cards-wrapper-amazon`}>
        {arrayForDisplay.map((essential) => {
          return (
            <a
              href={essential.link}
              className="card-link"
              key={essential.title}
            >
              <div className="card-essentials">
                <h2 className="black-text font-size-14">{essential.name}</h2>
                <img src={essential.photo} />
                <img src={buyButton} className="amazon-button" />
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ExampleItem;
