import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";

const NutrientDenseOptions = () => {
  const appContext = useContext(AppContext);

  return (
    <div className="nutrient-dense-wrapper">
      <div className="nutrient-dense-col">
        <div className="nutrient-dense-row-top nutrient-dense-row-top-title">
          Nutrient Dense
        </div>
        <div className="nutrient-dense-row-bottom">Typical</div>
      </div>
      <div></div>
      {appContext.nutrientDenseOptions &&
        appContext.nutrientDenseOptions.map((item) => {
          return (
            <div className="nutrient-dense-col">
              <a
                href={item.nutrient_dense["link"]}
                className="nutrient-dense-row-top"
              >
                <div className="nutrient-dense-title">
                  {item.nutrient_dense["name"]}
                </div>
                <img
                  src={item.nutrient_dense["photo"]}
                  className="nutrient-dense-image"
                />
              </a>
              <a
                href={item.typical["link"]}
                className="nutrient-dense-row-bottom"
              >
                <img
                  src={item.typical["photo"]}
                  className="nutrient-dense-image-bottom"
                />
                <div className="nutrient-dense-title">
                  {item.typical["name"]}
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default NutrientDenseOptions;
