import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AppContext from "./context/AppContext";
import Home from "./components/Main/Home";
import Navbar from "./components/Main/Navbar";
// import Footer from "./components/Main/Footer";
// import LeftSideBar from "./components/Sidebars/LeftSideBar";
// import Login from "./components/Auth/Login";
import RecipeApply from "./components/Auth/RecipeApply";

// import Weather from "./components/Main/Weather";
import TutorialsHome from "./components/Tutorials/TutorialsHome";
// import TutorialPage from "./components/Tutorials/TutorialPage";
import ArticlesHome from "./components/Foods/ArticlesHome";
import PythonBasics from "./components/Foods/PythonBasics";
import PythonBasicsLesson from "./components/Foods/PythonBasicsLesson";
import CalorieCalculator from "./components/Foods/CalorieCalculator";
// import ProductsHome from "./components/Products/ProductsHome";
// import ProductPage from "./components/Products/ProductPage";
// import NotFound from "./components/Main/NotFound";
// import Checkout from "./components/Stripe/Checkout";
// import Success from "./components/Stripe/Success";
// import Canceled from "./components/Stripe/Canceled";
import { Buffer } from "buffer";
import { getMongoDBIngredients } from "./lib/functions";

import "./App.css";
import HomeRecipes from "./components/Foods/Recipes/HomeRecipes";
import OneHomeRecipe from "./components/Foods/Recipes/OneHomeRecipe";

const App = () => {
  // amazon and youtube
  // const URL = "https://shielded-reef-94276.herokuapp.com";
  const URL = "http://localhost:4000";

  // const OLD URL_2 = "https://sheltered-brushlands-16281.herokuapp.com";
  // const URL_2 = "http://localhost:2000";
  const URL_2 = "https://secret-taiga-27046.herokuapp.com/";

  // const URL_3 = "http://localhost:2000";

  const [krogerAuth, setKrogerAuth] = useState();
  const [mongoNutrIngr, setMongoNutrIngr] = useState();
  const [mongoIngr, setMongoIngr] = useState();
  const [user, setUser] = useState();
  const [userProfile, setUserProfile] = useState();
  const [usdaGuidelinesData, setUsdaGuidelinesData] = useState();
  const [nutrientDenseOptions, setNutrientDenseOptions] = useState();
  const [zones, setZones] = useState([]);
  const [tbInfo, setTBInfo] = useState();
  const [homeRecipes, setHomeRecipes] = useState();
  const [usdaFoodCategories, setUsdaFoodCategories] = useState([]);
  const [foodCategories, setFoodCategories] = useState([]);

  useEffect(() => {
    fetch(`${URL_2}/get-usda-guidelines-data/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setUsdaGuidelinesData(data);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL_2}/get-nutrient-dense-options/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setNutrientDenseOptions(data);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL_2}/get-all-blue-zones/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setZones(data);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL_2}/get-all-house-recipes/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setHomeRecipes(data);
      });
  }, []);

  useEffect(() => {
    fetch(URL_2 + `/get-tom-brady/`)
      .then((response) => response.json())
      .then((data) => {
        setTBInfo(data[0]);
      });
  }, []);

  useEffect(() => {
    const encoded = Buffer.from(
      `${process.env.REACT_APP_KROGER_CLIENT_ID}:${process.env.REACT_APP_KROGER_CLIENT_SECRET}`,
      `ascii`
    );

    const authorization = "Basic " + encoded.toString("base64");

    const tokenUrl = `${process.env.REACT_APP_KROGER_OAUTH2_BASE_URL}/token`;
    fetch(tokenUrl, {
      method: "POST",
      headers: {
        "User-Agent": "",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authorization,
      },
      body: "grant_type=client_credentials&scope=product.compact",
    })
      .then((response) => response.json())
      .then((data) => {
        setKrogerAuth(data);
      });
  }, []);

  useEffect(() => {
    const dbIngredients = getMongoDBIngredients(
      URL_2 + `/get-nutrition-all-ingredients`
    );
    dbIngredients
      .then((response) => response.json())
      .then((data) => {
        // setReadMe(data);
        setMongoNutrIngr(data);
      });
  }, []);

  useEffect(() => {
    const dbIngredients = getMongoDBIngredients(
      URL_2 + `/get-mongo-ingredients`
    );
    dbIngredients
      .then((response) => response.json())
      .then((data) => {
        // setReadMe(data);
        setMongoIngr(data);
      });
  }, []);

  return (
    <div className="app">
      <Router>
        <AppContext.Provider
          value={{
            URL,
            URL_2,
            // URL_3,
            user,
            setUser,
            userProfile,
            setUserProfile,
            krogerAuth,
            mongoNutrIngr,
            mongoIngr,
            zones,
            setZones,
            tbInfo,
            setTBInfo,
            homeRecipes,
            setHomeRecipes,
            usdaFoodCategories,
            setUsdaFoodCategories,
            foodCategories,
            setFoodCategories,
            usdaGuidelinesData,
            nutrientDenseOptions,
          }}
        >
          <Navbar />
          {/* <LeftSideBar /> */}
          <div className="app-content">
            {/* <Route component={NotFound} /> */}
            {/* tutorials home */}
            <Route
              exact
              path="/tutorials"
              component={() => (
                <TutorialsHome
                  flexDir={"flex-col"}
                  cardSize={"wide"}
                  numberToShow={0}
                />
              )}
            />
            {/* routes for auth */}
            {/* <Route path="/login" component={Login} /> */}
            <Route path="/recipe-apply" component={RecipeApply} />
            {/* route for weather */}
            {/* <Route exact path="/weather" component={Weather} /> */}
            {/* routes for specific tutorials */}
            {/* <Route exact path="/tutorials/:slug" component={TutorialPage} /> */}
            {/* lessons homepage */}
            <Route exact path="/articles" component={ArticlesHome} />
            {/* add lesson routes to lessonsArray in LessonsHome */}
            <Route exact path="/fruits" component={PythonBasics} />
            <Route exact path="/beans" component={PythonBasics} />
            {/* <Route exact path="/kitchen-gadgets" component={PythonBasics} /> */}
            {/* routes for specific lesson */}
            <Route
              exact
              path="/fruits/:name/:slug"
              component={PythonBasicsLesson}
            />
            <Route
              exact
              path="/beans/:name/:slug"
              component={PythonBasicsLesson}
            />
            {/* <Route
              exact
              path="/kitchen-gadgets/:name/:slug"
              component={PythonBasicsLesson}
            /> */}
            <Route
              exact
              path="/calorie-calculator/"
              component={CalorieCalculator}
            />
            <Route exact path="/home-recipes/:name" component={OneHomeRecipe} />
            <Route exact path="/home-recipes/" component={HomeRecipes} />
            {/* products */}
            {/* <Route exact path="/products/:slug" component={ProductPage} /> */}
            {/* stripe */}
            {/* <Route exact path="/checkout/:lesson" component={Checkout} /> */}
            {/* <Route exact path="/success/" component={Success} /> */}
            {/* <Route exact path="/canceled/" component={Canceled} /> */}
            {/* home */}
            <Route exact path="/" component={Home} />
          </div>
          {/* <Footer /> */}
        </AppContext.Provider>
      </Router>
    </div>
  );
};

export default App;
