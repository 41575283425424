export const getMongoDBIngredients = (url) => {
  return fetch(url);
};

export const getSlug = (foodName, mongoIngr) => {
  const ingArray = mongoIngr.filter((item) => {
    // console.log(item["Name"], "foodName: " + foodName);
    return item["name"] === foodName;
  });

  if (ingArray[0]) {
    return ingArray[0]["slug"];
  } else {
    return `${foodName}-nutrition-info`;
  }
};

export const calculateRecipeNutritionTotals = (recipeIngredients, setFunc) => {
  const recipeTotalsObj = {};
  recipeIngredients.forEach((ingObj) => {
    Object.keys(ingObj.option).forEach((item) => {
      let value = ingObj.option[item];
      if (recipeTotalsObj[item]) {
        if (item === "Grams_1") {
          recipeTotalsObj[item] +=
            parseFloat(value) * parseFloat(ingObj.serving);
        } else if (isNaN(value) === false) {
          recipeTotalsObj[item] =
            recipeTotalsObj[item] +
            parseFloat(value) *
              parseFloat(ingObj.serving) *
              (parseFloat(ingObj.option["Grams_1"]) / 100);
        } else {
          recipeTotalsObj[item] += value + "; ";
        }
      } else {
        if (item === "Grams_1") {
          recipeTotalsObj[item] =
            parseFloat(value) * parseFloat(ingObj.serving);
        } else if (isNaN(value) === false) {
          recipeTotalsObj[item] =
            parseFloat(value) *
            parseFloat(ingObj.serving) *
            (parseFloat(ingObj.option["Grams_1"]) / 100);
        } else {
          recipeTotalsObj[item] = value + "; ";
        }
      }
    });
  });
  setFunc(recipeTotalsObj);
};

export const calculateFoodCategoryTotals = (
  recipeIngredients,
  setFunc,
  foodCategories
) => {
  if (recipeIngredients) {
    let copyFoodCategories = {};

    Object.keys(foodCategories).forEach((item) => {
      copyFoodCategories[item] = 0;
    });

    recipeIngredients.forEach((item) => {
      copyFoodCategories[item.option["Type"]] += parseFloat(item.serving);
    });
    setFunc(copyFoodCategories);
  }
};

export const convert100GtoServSize = (data) => {
  const newObj = {};
  Object.keys(data).forEach((item) => {
    if (!isNaN(data[item]) && item !== "Grams_1") {
      newObj[item] = (
        parseFloat(data[item]) *
        (parseFloat(data["Grams_1"]) / 100)
      ).toFixed(1);
    } else if (isNaN(data[item]) || item === "Grams_1") {
      newObj[item] = data[item];
    }
  });
  return newObj;
};

export const validateEmail = (email) => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const validateURL = (str) => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  if (str.includes("https://")) {
    return url.protocol === "https:";
  } else {
    return false;
  }
};

export const validateName = (str) => {
  if (str.length >= 3 && str.length <= 80) {
    return true;
  } else {
    return false;
  }
};

export const validateDesc = (str) => {
  if (str.length >= 10 && str.length <= 300) {
    return true;
  } else {
    return false;
  }
};

export const validateNumber = (str) => {
  if (!isNaN(str)) {
    return true;
  } else {
    return false;
  }
};

export const validateIngr = (arr) => {
  if (arr.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const toggleTemp = (tempUnit, setTempUnit) => {
  if (tempUnit === "C") {
    setTempUnit("F");
  }
  if (tempUnit === "F") {
    setTempUnit("C");
  }
};

export const calculateTemp = (tempNumber, unit) => {
  if (unit === "C") {
    return tempNumber.toFixed(1);
  }
  if (unit === "F") {
    return (parseFloat(tempNumber) * (9 / 5) + 32).toFixed(1);
  }
};

export const getMonth = (number) => {
  if (parseInt(number) === 1) {
    return "Jan";
  }
  if (parseInt(number) === 2) {
    return "Feb";
  }
  if (parseInt(number) === 3) {
    return "Mar";
  }
  if (parseInt(number) === 4) {
    return "Apr";
  }
  if (parseInt(number) === 5) {
    return "May";
  }
  if (parseInt(number) === 6) {
    return "Jun";
  }
  if (parseInt(number) === 7) {
    return "Jul";
  }
  if (parseInt(number) === 8) {
    return "Aug";
  }
  if (parseInt(number) === 9) {
    return "Sept";
  }
  if (parseInt(number) === 10) {
    return "Oct";
  }
  if (parseInt(number) === 11) {
    return "Nov";
  }
  if (parseInt(number) === 12) {
    return "Dec";
  }
};
