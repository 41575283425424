import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Sidebar = (props) => {
  const [aTags, setATags] = useState([]);
  const [sectionsArray, setSectionsArray] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const elements = document.getElementsByTagName("a");
    setATags(Array.from(elements));
  }, [props]);

  useEffect(() => {
    let tempArray = [];
    aTags.forEach((element) => {
      // create array of section headings
      if (
        element.hash &&
        element.id !== "hide" &&
        !element.classList.contains("section-link")
      ) {
        const sectionTitle = element.hash
          .replace("#", "")
          .replace(/-/g, " ")
          .replace(/python/g, "Python");

        const polishedTitle =
          sectionTitle.charAt(0).toUpperCase() + sectionTitle.substr(1);

        const sectionObject = {
          title: polishedTitle,
          hash: element.hash,
        };

        tempArray.push(sectionObject);
      }
    });

    setSectionsArray(tempArray);
  }, [aTags]);

  useEffect(() => {
    // if not a hash link scroll to top

    aTags.forEach((element) => {
      // scroll to section
      if (element.hash === location.hash) {
        element.scrollIntoView();
      }
      // scroll to top
      if (location.hash === "") {
        window.scrollTo(0, 0);
      }
    });
  }, [location.hash]);

  return (
    <>
      {sectionsArray.map((section) => {
        return (
          <a
            href={`${section.hash.replace(/ /g, "-")}`}
            className="section-link"
            key={section.title}
          >
            {section.title}
          </a>
        );
      })}
    </>
  );
};

export default Sidebar;
