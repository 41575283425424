import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import FoodPieChart from "./FoodPieChart";
import FoodBarChart from "./FoodBarChart";
import { convert100GtoServSize } from "../../lib/functions";
// import "../../App.css";

const Nutrition = (props) => {
  const {
    searchTerms,
    calculatedRecipeObj,
    theRecipeIngredients,
    theRecipeNumServed,
  } = props;
  const appContext = useContext(AppContext);
  const [nutritionItems, setNutritionItems] = useState([]);
  const [microNutrients, setMicroNutrients] = useState([]);
  const [macroNutrients, setMacroNutrients] = useState([]);
  const [numServings, setNumServings] = useState(1);
  const [servType, setServType] = useState("recipe");
  const [sugarPercent, setSugarPercent] = useState(
    <div className={`food-label-green`}>
      {"0.0" + "% added sugar calories per serving"}
    </div>
  );

  const excludedItems = [
    "Name",
    "_id",
    "NDB_No",
    "Grams_Desc1",
    "Grams_1",
    "Grams_Desc2",
    "Grams_2",
    "Energy_(Kcal)",
    "Fatty_Acid_Mono_(44)_(g)",
    "Fatty_Acid_Saturated_(15)_(g)",
    "Fatty_Acid_Poly_(22)_(g)",
    "Cholesterol_(300)_(mg)",
    "Sodium_(2300)_(mg)",
    "Carbohydrates_(275)_(g)",
    "Sugar_(?)_(g)",
    "Fiber_(28)_(g)",
    "Protein_(50)_(g)",
    "Lipid_Total_(78)_(g)",
    "Refuse_Pct",
    "Ash_(g)",
    "Water_(g)",
    "Type",
  ];

  const macroNutr = [
    "Fatty_Acid_Mono_(44)_(g)",
    "Fatty_Acid_Saturated_(15)_(g)",
    "Fatty_Acid_Poly_(22)_(g)",
    "Cholesterol_(300)_(mg)",
    "Sodium_(2300)_(mg)",
    "Sugar_(?)_(g)",
    "Fiber_(28)_(g)",
    "Protein_(50)_(g)",
    "Water_(g)",
  ];

  const getDRV = (itemName) => {
    const drvArray = itemName.split("_");
    let drv;

    drvArray.forEach((element) => {
      if (element.includes("(") && isNaN(element[1]) === false) {
        drv =
          parseFloat(
            element
              .replace("(", "")
              .replace(")", "")
              .replace("-", ".")
              .replace("?", "1")
          ) / 100;
      }
    });
    return drv;
  };

  useEffect(() => {
    const tempArray2 = [];
    if (nutritionItems) {
      Object.keys(nutritionItems).forEach((item) => {
        let itemDRV = getDRV(item);

        if (!itemDRV) {
          itemDRV = 30;
        }

        if (!excludedItems.includes(item)) {
          tempArray2.push({
            name: item,
            value: (
              (parseFloat(nutritionItems[item]) / itemDRV) *
              numServings
            ).toFixed(1),
          });
        }
      });

      setMicroNutrients(tempArray2);

      const tempArray = [];
      Object.keys(nutritionItems).forEach((item) => {
        if (macroNutr.includes(item)) {
          tempArray.push({
            name: item,
            value:
              item === "Sodium_(2300)_(mg)" || item === "Cholesterol_(300)_(mg)"
                ? parseFloat(nutritionItems[item]) * numServings
                : parseFloat(nutritionItems[item]) * 1000 * numServings,
          });
        }
      });
      setMacroNutrients(tempArray);
    }
  }, [nutritionItems, numServings]);

  // useEffect(() => {
  //   console.log(microNutrients);
  // }, [microNutrients, macroNutrients]);

  const getNutrition = (name) => {
    fetch(appContext.URL_2 + `/get-nutrition/${name}`)
      .then((response) => response.json())
      .then((data) => {
        const newObj = convert100GtoServSize(data);
        setNutritionItems(newObj);
      });
  };

  useEffect(() => {
    if (searchTerms) {
      getNutrition(searchTerms);
    }
  }, [searchTerms]);

  useEffect(() => {
    if (calculatedRecipeObj) {
      setNutritionItems(calculatedRecipeObj);
    }
  }, [calculatedRecipeObj]);

  const handleNumInput = (e) => {
    if (!e.target.value) {
      setNumServings(parseFloat(0));
    } else {
      setNumServings(parseFloat(e.target.value));
    }
  };

  const handleInputChangeRvsS = (e) => {
    let value = e.target.value;
    setServType(value);
  };

  useEffect(() => {
    if (theRecipeNumServed) {
      if (servType === "recipe") {
        setNumServings(numServings / 1);
      } else if (servType === "serving") {
        setNumServings(1 / theRecipeNumServed);
      }
    }
  }, [servType]);

  const calcSugarPct = () => {
    // console.log(theRecipe);
    const sugar = theRecipeIngredients.filter((item) => {
      return item.option["Name"] === "sugar";
    });

    // (enegergy / 100 g) * (numgrams) = energy
    if (sugar.length > 0) {
      const sugarTotalCal =
        (parseFloat(sugar[0].option["Energy_(Kcal)"]) / 100) *
        parseFloat(sugar[0].option["Grams_1"]) *
        parseFloat(sugar[0].serving);

      const recipeTotalCal = parseFloat(nutritionItems["Energy_(Kcal)"]);

      const sugarPct =
        ((sugarTotalCal * numServings) / (recipeTotalCal * numServings)) * 100;

      let color = "";

      if (sugarPct <= 10) {
        color = "green";
      } else {
        color = "red";
      }
      setSugarPercent(
        <div className={`food-label-${color}`}>
          {sugarPct.toFixed(1) + "% added sugar calories per serving"}
        </div>
      );

      // return (
      //   <div className={`food-label-${color}`}>
      //     {sugarPct.toFixed(1) + "% added sugar calories per serving"}
      //   </div>
      // );
    } else {
      setSugarPercent(
        <div className={`food-label-green`}>
          {"0.0" + "% added sugar calories per serving"}
        </div>
      );
    }
  };

  const calcSaturatedFat = () => {
    const satFatTotalCal = parseFloat(
      nutritionItems["Fatty_Acid_Saturated_(15)_(g)"] * 9
    );
    const recipeTotalCal = parseFloat(nutritionItems["Energy_(Kcal)"]);
    const satFatPct = (satFatTotalCal / recipeTotalCal) * 100;
    let color = "";
    if (satFatPct <= 10) {
      color = "green";
    } else {
      color = "red";
    }
    return (
      <div className={`food-label-${color}`}>
        {satFatPct.toFixed(1) + "% saturated fat calories per serving"}
      </div>
    );
  };

  const calcSodium = () => {
    const sodiumAmt = parseFloat(
      nutritionItems["Sodium_(2300)_(mg)"] * numServings
    );

    const sodiumPctDaily = (sodiumAmt / 2300) * 100;
    let color = "";
    if (sodiumAmt <= 2300 / 2) {
      color = "green";
    } else {
      color = "red";
    }
    return (
      <div className={`food-label-${color}`}>
        {sodiumAmt.toFixed(1) +
          "mg sodium per serving is " +
          sodiumPctDaily.toFixed(1) +
          "% of daily limit"}
      </div>
    );
  };

  const calcIronCalcium = () => {
    const calciumMg = parseFloat(nutritionItems["Calcium_(1300)_(mg)"]);
    const ironMg = parseFloat(nutritionItems["Iron_(18)_(mg)"]);

    const ratio = calciumMg / ironMg;
    const publishedRatio = 3 / 0.01;

    let comparison = "";

    let color = "";
    if (ratio <= publishedRatio) {
      color = "green";
      comparison = "less than";
    } else {
      color = "red";
      comparison = "greater than";
    }
    return (
      <div className={`food-label-${color}`}>
        {ratio.toFixed(1) +
          " calcium to iron ratio is " +
          comparison +
          " the ratio of " +
          publishedRatio}
      </div>
    );
  };

  useEffect(() => {
    if (
      nutritionItems !== [] &&
      nutritionItems["Name"] &&
      theRecipeIngredients.length > 0
    ) {
      calcSugarPct();
    }
  }, [theRecipeIngredients, nutritionItems]);

  // 3 mg calcium to 0.01 mg iron
  // https://pubmed.ncbi.nlm.nih.gov/1600930/#:~:text=No%20inhibiting%20effect%20on%20iron,amounts%20of%20calcium%20were%20given.

  return (
    <div className="nutrition-item-wrapper">
      {searchTerms && (
        <>
          <div className="nutrition-item-name">{nutritionItems["Name"]}</div>
          <div className="nutrition-item-serv-size">
            {nutritionItems["Grams_Desc1"]} ({nutritionItems["Grams_1"]} g)
          </div>
        </>
      )}

      <div className="macro-nutrient-wrapper">
        <div className="macro-nutrient-label">
          {theRecipeNumServed && nutritionItems && (
            <>
              <div className="amount-per-serving">
                Amount per{" "}
                {servType === "recipe" ? (
                  <input
                    type="number"
                    className="amount-per-serv-input"
                    min="1"
                    max="100"
                    onInput={handleNumInput}
                    value={numServings}
                  />
                ) : (
                  ""
                )}
                <select
                  name="meal"
                  id="meal"
                  onChange={handleInputChangeRvsS}
                  className="calculator-input recipe-form-input"
                >
                  <option value="recipe">recipe</option>
                  <option value="serving">serving</option>
                </select>
              </div>
            </>
          )}

          <div className="nutrition-calories">
            Calories{" "}
            {(
              parseFloat(nutritionItems["Energy_(Kcal)"]) * numServings
            ).toFixed(1)}
          </div>

          {theRecipeNumServed && (
            <div className="nutrition-item-scientific">
              <div className="nutr-item-amount">
                Calories per serving{" "}
                {(
                  (parseFloat(nutritionItems["Energy_(Kcal)"]) * numServings) /
                  parseFloat(theRecipeNumServed)
                ).toFixed(1)}
              </div>
            </div>
          )}

          <div className="nutrition-item-scientific">
            <div className="nutr-item-amount">
              Fats{" "}
              {parseFloat(
                nutritionItems["Lipid_Total_(78)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Lipid_Total_(78)_(g)"] / 78) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrient-amount">
            <div className="nutr-item-amount">
              Fatty Acid Mono{" "}
              {(
                parseFloat(nutritionItems["Fatty_Acid_Mono_(44)_(g)"]) *
                numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Fatty_Acid_Mono_(44)_(g)"] / 44) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrient-amount">
            <div className="nutr-item-amount">
              Fatty Acid Saturated
              {parseFloat(
                nutritionItems["Fatty_Acid_Saturated_(15)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Fatty_Acid_Saturated_(15)_(g)"] / 15) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrient-amount">
            <div className="nutr-item-amount">
              Fatty Acid Poly{" "}
              {parseFloat(
                nutritionItems["Fatty_Acid_Poly_(22)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Fatty_Acid_Poly_(22)_(g)"] / 22) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrition-item-scientific">
            <div className="nutr-item-amount">
              Cholesterol{" "}
              {parseFloat(
                nutritionItems["Cholesterol_(300)_(mg)"] * numServings
              ).toFixed(1)}{" "}
              mg
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Cholesterol_(300)_(mg)"] / 300) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrition-item-scientific">
            <div className="nutr-item-amount">
              Sodium{" "}
              {parseFloat(
                nutritionItems["Sodium_(2300)_(mg)"] * numServings
              ).toFixed(1)}{" "}
              mg
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Sodium_(2300)_(mg)"] / 2300) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrition-item-scientific">
            <div className="nutr-item-amount">
              Carbohydrates{" "}
              {parseFloat(
                nutritionItems["Carbohydrates_(275)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Carbohydrates_(275)_(g)"] / 275) *
                  100 *
                  numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrient-amount">
            <div className="nutr-item-amount">
              Sugar{" "}
              {parseFloat(
                nutritionItems["Sugar_(?)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">-</div>
          </div>
          <div className="nutrient-amount">
            <div className="nutr-item-amount">
              Fiber{" "}
              {parseFloat(
                nutritionItems["Fiber_(28)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Fiber_(28)_(g)"] / 28) * 100 * numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrition-item-scientific">
            <div className="nutr-item-amount">
              Protein{" "}
              {parseFloat(
                nutritionItems["Protein_(50)_(g)"] * numServings
              ).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">
              {`${parseFloat(
                (nutritionItems["Protein_(50)_(g)"] / 50) * 100 * numServings
              ).toFixed(1)} %`}
            </div>
          </div>
          <div className="nutrition-item-scientific">
            <div className="nutr-item-amount">
              Water{" "}
              {parseFloat(nutritionItems["Water_(g)"] * numServings).toFixed(1)}{" "}
              g
            </div>
            <div className="nutr-item-drv">-</div>
          </div>
        </div>
        <div className="macro-nutrient-chart">
          <FoodPieChart macroNutrients={macroNutrients} />
          <FoodPieChart
            macroNutrients={macroNutrients.filter((item) => {
              return !item.name.includes("Water");
            })}
          />
        </div>
      </div>
      {nutritionItems && nutritionItems["Name"] && theRecipeIngredients && (
        <div className="micro-nutrient-wrapper">
          <div className="food-label-warnings">
            {sugarPercent}
            {/* {Object.keys(nutritionItems["Name"]).includes("sugar") ? (
              sugarPercent
            ) : (
              <div className="food-label-green">No added sugar</div>
            )} */}
            <div>USDA recommends less than 10 percent of calories per day</div>
          </div>
          <div className="food-label-warnings">
            {calcSaturatedFat()}
            <div>USDA recommends less than 10 percent of calories per day</div>
          </div>

          <div className="food-label-warnings">
            {calcSodium()}
            <div>USDA recommends less than 2,300 milligrams per day</div>
          </div>

          <div className="food-label-warnings">
            {calcIronCalcium()}
            <div>
              <a
                id="hide"
                href="https://pubmed.ncbi.nlm.nih.gov/1600930/#:~:text=No%20inhibiting%20effect%20on%20iron,amounts%20of%20calcium%20were%20given"
              >
                One study found no inhibiting effect on iron absorption by 3 mg
                calcium per 0.01 mg iron ({3 / 0.01} ratio)
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="micro-nutrient-wrapper">
        <div>
          <div className="amount-per-serving">
            % of Daily Recommended Value Based on 2000 Calories:
          </div>
          <FoodBarChart microNutrients={microNutrients} />
        </div>
        <div className="nutrient-wrapper-wrapper">
          {Object.keys(nutritionItems).map(function (key) {
            if (!excludedItems.includes(key)) {
              return (
                <div className="nutrient-wrapper">
                  <div className="nutrient-name">
                    {key.replace(/_/g, " ").replace("-", ".")}
                  </div>
                  <div className="nutrient-name">
                    {(parseFloat(nutritionItems[key]) * numServings).toFixed(2)}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Nutrition;
