import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import OneRecipe from "../OneRecipe";
import USDAMain from "../USDA/USDAMain";
import Nutrition from "../Nutrition";
import HNTDCalculator from "../HNTD/HNTDCalculator";
import {
  calculateRecipeNutritionTotals,
  calculateFoodCategoryTotals,
} from "../../../lib/functions";
import BzCalculator from "../BlueZones/BzCalculator";
import TBCalculator from "../TomBrady/TBCalculator";
import Sidebar from "../Sidebar";
import Loader from "../../Auth/Loader";

const OneHomeRecipe = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [theRecipe, setTheRecipe] = useState();
  const [calculatedRecipeObj, setcalculatedRecipeObj] = useState();

  useEffect(() => {
    if (appContext.mongoNutrIngr) {
      const typesSet = new Set(
        appContext.mongoNutrIngr.map((item) => item["Type"])
      );
      const typesArray = Array.from(typesSet);
      const typesObj = {};
      typesArray.forEach((item) => {
        typesObj[item] = 0;
      });
      if (appContext.homeRecipes) {
        appContext.setFoodCategories(typesObj);
      }
    }
  }, [appContext.mongoNutrIngr, appContext.homeRecipes]);

  useEffect(() => {
    const pathName = location.pathname.replace("/home-recipes/", "");
    console.log(pathName);
    if (appContext.homeRecipes && appContext.foodCategories) {
      const filteredRecipe = appContext.homeRecipes.filter((item) => {
        return (
          item["name"].replace(/ /g, "-").replace(/, /g, "").toLowerCase() ===
          pathName
        );
      });
      console.log(filteredRecipe[0]);
      setTheRecipe(filteredRecipe[0]);
    }
  }, [appContext.foodCategories]);

  useEffect(() => {
    if (theRecipe) {
      calculateRecipeNutritionTotals(
        theRecipe["nutrition"],
        setcalculatedRecipeObj
      );

      calculateFoodCategoryTotals(
        theRecipe["nutrition"],
        appContext.setFoodCategories,
        appContext.foodCategories
      );
    }
  }, [theRecipe]);

  // useEffect(() => {
  //   if (calculatedRecipeObj) {
  //     console.log(calculatedRecipeObj);
  //   }
  // }, [calculatedRecipeObj]);

  return (
    <div className="lesson-wrapper-col">
      {(!theRecipe || !calculatedRecipeObj) && <Loader />}
      {theRecipe && calculatedRecipeObj && (
        <>
          <div className="sections-wrapper">
            <Sidebar />
          </div>

          {theRecipe && (
            <OneRecipe
              item={theRecipe}
              parent="one-home-recipe"
              nutritionInfoIng={theRecipe["nutrition"]}
            />
          )}

          <div className="calorie-calculator-selector-wrapper">
            {appContext.usdaGuidelinesData && (
              <USDAMain recipeIngredients={theRecipe["nutrition"]} />
            )}
          </div>
          <div className="calorie-calculator-selector-wrapper">
            <a
              href={`#food-label`}
              className="calorie-calculator-arrow-wrapper"
            >
              <h1
                id={`food-label`}
                className="calorie-calculator-selector-wrapper"
              >
                Food Label
              </h1>
            </a>
          </div>
          {calculatedRecipeObj && theRecipe && (
            <Nutrition
              searchTerms={""}
              calculatedRecipeObj={calculatedRecipeObj}
              theRecipe={theRecipe}
              theRecipeIngredients={theRecipe["nutrition"]}
              theRecipeNumServed={theRecipe["num_served"]}
            />
          )}
          {theRecipe && calculatedRecipeObj && (
            <BzCalculator
              recipeIngredients={theRecipe["nutrition"]}
              calculatedRecipeObj={calculatedRecipeObj}
            />
          )}
          {theRecipe && appContext.foodCategories && (
            <HNTDCalculator recipeIngredients={theRecipe["nutrition"]} />
          )}
          {theRecipe && calculatedRecipeObj && (
            <TBCalculator
              recipeIngredients={theRecipe["nutrition"]}
              calculatedRecipeObj={calculatedRecipeObj}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OneHomeRecipe;
