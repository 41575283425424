import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import OneRecipe from "./OneRecipe";
import AppContext from "../../context/AppContext";
import {
  validateName,
  validateURL,
  validateDesc,
  validateNumber,
  validateIngr,
} from "../../lib/functions";
import profilePhoto from "../../images/profile_photo.png";
import SingleRecipeLabel from "./SingleRecipeLabel";

const RecipeForm = (props) => {
  const appContext = useContext(AppContext);
  const [redirect, setRedirect] = useState();

  const [isValidName, setIsValidName] = useState(false);
  const [isValidURL, setIsValidURL] = useState(false);
  const [isValidActive, setIsValidActive] = useState(false);
  const [isValidPassive, setIsValidPassive] = useState(false);
  const [isValidNumServed, setIsValidNumServed] = useState(false);
  const [isValidMeal, setIsValidMeal] = useState(false);
  const [isValidInstr, setIsValidInstr] = useState(false);
  const [isValidDesc, setIsValidDesc] = useState(false);
  const [isValidIngr, setIsValidIngr] = useState(false);

  const {
    recipeIngredients,
    handleDeleteIng,
    foodCategories,
    formData,
    setFormData,
  } = props;
  const [previewRecipe, setPreviewRecipe] = useState();

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const checkInput = (inputName) => {
    if (formData && formData[inputName]) {
      return true;
    } else if (formData && !formData[inputName]) {
      return false;
    }
  };

  const handlePreviewRecipe = () => {
    const finalObject = formData;
    finalObject["created_date"] = Date.now();
    const recipeAuthor =
      appContext.userProfile && appContext.userProfile["_id"] !== undefined
        ? appContext.userProfile["_id"]
        : "guest";
    finalObject["user_id"] = recipeAuthor;

    setPreviewRecipe(finalObject);
  };

  const handleSave = () => {
    if (previewRecipe) {
      const ingsObj = {};
      Object.keys(previewRecipe.ingredients).forEach((item) => {
        ingsObj[item.replace(/ /g, "_")] = previewRecipe.ingredients[[item]];
      });

      const forSaveObj = {};
      Object.keys(previewRecipe).forEach((item) => {
        if (item === "ingredients") {
          forSaveObj[item] = ingsObj;
        } else {
          forSaveObj[item] = previewRecipe[item];
        }
      });

      fetch(`${appContext.URL_2}/add-house-recipe/`, {
        method: "POST",

        body: JSON.stringify(forSaveObj),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
    }
  };

  const handleApply = () => {
    setRedirect("/recipe-apply");
  };

  useEffect(() => {
    if (formData && formData.name) {
      const nameValidity = validateName(formData.name);
      setIsValidName(nameValidity);
    }

    if (formData && formData.link) {
      const urlValidity = validateURL(formData.link);
      setIsValidURL(urlValidity);
    }

    if (formData && formData.active_prep_time) {
      const activeValidity = validateNumber(formData.active_prep_time);
      setIsValidActive(activeValidity);
    }

    if (formData && formData.passive_prep_time) {
      const passiveValidity = validateNumber(formData.passive_prep_time);
      setIsValidPassive(passiveValidity);
    }

    if (formData && formData.num_served) {
      const numServedValidity = validateNumber(formData.num_served);
      setIsValidNumServed(numServedValidity);
    }

    if (formData && formData.meal) {
      setIsValidMeal(true);
    }

    if (formData && formData.instructions) {
      const instructionsValidity = validateDesc(formData.instructions);
      setIsValidInstr(instructionsValidity);
    }

    if (formData && formData.description) {
      const descriptionValidity = validateDesc(formData.description);
      setIsValidDesc(descriptionValidity);
    }

    if (formData && formData.ingredients) {
      const ingredientsValidity = validateIngr(formData.ingredients);
      setIsValidIngr(ingredientsValidity);
    }
  }, [formData]);

  return (
    <>
      <div className="calorie-calculator-selector-wrapper">
        <a href="#recipe-form" id="recipe-form">
          <h1 className="">Recipe Form</h1>
        </a>
      </div>
      <div className="calorie-calculator-form-wrapper">
        {redirect && <Redirect to={redirect} />}
        <div className="recipe-form-wrapper">
          <div className="recipe-user">
            <img
              src={appContext.user ? appContext.user.photo : profilePhoto}
              className="recipe-user-profile-photo"
            />
            <div>{appContext.user ? appContext.user.displayName : "Guest"}</div>
          </div>

          <div className="recipe-form">
            {/* <img src={appContext.user ? appContext.user.photo : ""} /> */}
            <div className="apply-input-wrapper">
              <input
                name="name"
                type="text"
                placeholder="Recipe Name"
                className="calculator-input recipe-form-input"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidName}`}
              >
                {isValidName && checkInput("name")
                  ? "✓"
                  : "Must be 3-80 characters"}
              </div>
            </div>
          </div>

          <div className="recipe-form">
            <div className="apply-input-wrapper">
              <input
                name="link"
                type="text"
                placeholder="Recipe Link"
                className="calculator-input recipe-form-input"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidURL}`}
              >
                {isValidURL && checkInput("link")
                  ? "✓"
                  : "Valid https:// url required"}
              </div>
            </div>
          </div>

          <div className="apply-input-wrapper">
            <input
              name="active_prep_time"
              type="number"
              placeholder="Active Prep Mins"
              className="calculator-input recipe-form-input"
              onChange={handleInputChange}
            />
            <div
              className={`apply-form-label apply-form-label-${isValidActive}`}
            >
              {isValidActive && checkInput("active_prep_time")
                ? "✓"
                : "Number required"}
            </div>
          </div>

          <div className="recipe-form">
            <div className="apply-input-wrapper">
              <input
                name="passive_prep_time"
                type="number"
                placeholder="Passive Prep Mins"
                className="calculator-input recipe-form-input"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidPassive}`}
              >
                {isValidPassive && checkInput("passive_prep_time")
                  ? "✓"
                  : "Number required"}
              </div>
            </div>
          </div>

          <div className="recipe-form">
            <div className="apply-input-wrapper">
              <input
                name="num_served"
                type="number"
                placeholder="Serves How Many"
                className="calculator-input recipe-form-input"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidNumServed}`}
              >
                {isValidNumServed && checkInput("num_served")
                  ? "✓"
                  : "Number required"}
              </div>
            </div>
          </div>

          <div className="recipe-form">
            <div className="apply-input-wrapper">
              {/* <input
                name="num_served"
                type="number"
                placeholder="Severs How Many"
                className="calculator-input recipe-form-input"
                onChange={handleInputChange}
              /> */}
              <select
                name="meal"
                id="meal"
                onChange={handleInputChange}
                className="calculator-input recipe-form-input"
              >
                <option value="">Meal Type</option>
                <option value="breakfast">breakfast</option>
                <option value="lunch">lunch</option>
                <option value="dinner">dinner</option>
                <option value="snack">snack</option>
              </select>
              <div
                className={`apply-form-label apply-form-label-${isValidMeal}`}
              >
                {isValidMeal && checkInput("meal") ? "✓" : "Choose a meal type"}
              </div>
            </div>
          </div>

          <div className="recipe-form">
            <div className="apply-input-wrapper">
              <textarea
                name="instructions"
                placeholder="Instructions"
                className="calculator-input recipe-form-input recipe-form-textarea"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidInstr}`}
              >
                {isValidInstr && checkInput("instructions")
                  ? "✓"
                  : "Must be between 10-300 characters"}
              </div>
            </div>
          </div>

          <div className="recipe-form">
            <div className="apply-input-wrapper">
              <textarea
                name="description"
                placeholder="Description"
                className="calculator-input recipe-form-input recipe-form-textarea"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidDesc}`}
              >
                {isValidDesc && checkInput("description")
                  ? "✓"
                  : "Must be between 10-300 characters"}
              </div>
            </div>
          </div>
          <div className="recipe-form-ingredients">
            {recipeIngredients && (
              <>
                <div className="calorie-calculator-items-wrapper">
                  <div className="selected-items">
                    <div className="ing-item-wrapper">
                      <div className="usda-table-title usda-table-blue-bkgd-color">
                        Ingredient
                      </div>
                      <div className="usda-table-title">Servings</div>
                      <div className="usda-table-title">Serving Size</div>
                      <div className="usda-table-title">Delete</div>
                    </div>
                    {recipeIngredients.map((item) => {
                      return (
                        <div
                          key={JSON.stringify(item.option)}
                          className="usda-row-wrapper"
                        >
                          <div className="usda-table-group">
                            {item.option["Name"]}
                          </div>
                          <div className="usda-table-group">{item.serving}</div>
                          <div className="usda-table-group">
                            {item.option["Grams_Desc1"]}
                          </div>
                          <div className="usda-table-sub-amount">
                            <div
                              id={JSON.stringify(item)}
                              title={item.option["Name"]}
                              onClick={handleDeleteIng}
                              className="ing-delete"
                            >
                              X
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="selected-categories">
                    <div className="ing-item-wrapper">
                      <div className="usda-row-wrapper">
                        <div className="usda-table-title usda-table-blue-bkgd-color">
                          Ingredient
                        </div>
                        <div className="usda-table-title">Servings</div>
                        <div className="usda-table-title">Serving Size</div>
                        <div className="usda-table-title">Delete</div>
                      </div>
                      {Object.keys(foodCategories).map((key) => {
                        return (
                          <div key={key} className="usda-row-wrapper">
                            <div className="usda-group-title">
                              {foodCategories[key]}
                            </div>
                            <div className="usda-group-title">{key}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
                <div className={`apply-form-label apply-form-label-true`}>
                  {"✓"}
                </div>
              </>
            )}

            {!recipeIngredients && (
              <div className="recipe-form">
                <h3>Add Ingredients</h3>
                <div
                  className={`apply-form-label apply-form-label-${isValidIngr}`}
                >
                  {isValidIngr ? "✓" : ""}
                </div>
              </div>
            )}
          </div>

          {
            <>
              <button
                disabled={
                  !(
                    formData &&
                    Object.keys(formData).length >= 7 &&
                    !Object.values(formData).includes("") &&
                    recipeIngredients &&
                    recipeIngredients.length > 0 &&
                    isValidURL &&
                    isValidMeal
                  )
                }
                className={`to-form-button to-form-button-${
                  formData && Object.keys(formData).length >= 7
                }`}
                onClick={handlePreviewRecipe}
              >
                Preview
              </button>
              {previewRecipe && (
                <OneRecipe
                  item={previewRecipe}
                  parent="recipe-form"
                  nutritionInfoIng={recipeIngredients}
                />
              )}
            </>
          }
          <>
            <div className="calorie-calculator-selector-wrapper">
              <a href="#save-recipe" id="save-recipe">
                <h1>Save Recipe</h1>
              </a>
            </div>
            {!appContext.user && (
              <>
                <h2 className="margin-bottom-40">
                  Want to save your recipe? Share it with family and friends?
                </h2>
                <h3 className="margin-bottom-40">
                  Apply below to join us in posting recipes to help people eat
                  smarter.
                </h3>
                <h3 className="margin-bottom-40">
                  Your recipes, our analytical tools.
                </h3>
                <button
                  className={`to-form-button to-form-button-true`}
                  onClick={handleApply}
                >
                  Apply
                </button>
              </>
            )}
            {/* {previewRecipe && (
              <button
                className={`to-form-button to-form-button-true`}
                onClick={handleSave}
              >
                Save
              </button>
            )} */}
            {/* {previewRecipe && appContext.user && (
              <button
                disabled={
                  !(
                    formData &&
                    formData.name &&
                    isValidName &&
                    formData.link &&
                    isValidURL &&
                    formData.active_prep_time &&
                    isValidActive &&
                    formData.passive_prep_time &&
                    isValidPassive &&
                    isValidNumServed &&
                    formData.instructions &&
                    isValidInstr &&
                    formData.description &&
                    isValidDesc &&
                    formData.ingredients &&
                    isValidIngr
                  )
                }
                className={`to-form-button to-form-button-${
                  formData &&
                  formData.name &&
                  isValidName &&
                  formData.link &&
                  isValidURL &&
                  formData.active_prep_time &&
                  isValidActive &&
                  formData.passive_prep_time &&
                  isValidPassive &&
                  isValidNumServed &&
                  formData.instructions &&
                  isValidInstr &&
                  formData.description &&
                  isValidDesc &&
                  formData.ingredients &&
                  isValidIngr
                }`}
                onClick={handleSave}
              >
                Save
              </button>
            )} */}
          </>
        </div>
      </div>
    </>
  );
};

export default RecipeForm;
