import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";

const USDAMain = (props) => {
  const appContext = useContext(AppContext);
  const { recipeIngredients } = props;
  const [categoryTotals, setCategoryTotals] = useState({});
  //   useEffect(() => {
  //     fetch(`${appContext.URL_2}/get-all-blue-zones/`, {
  //       method: "GET",
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setZones(data);
  //       });
  //   }, []);

  useEffect(() => {
    let tempObjCategoryTotals = {};
    if (recipeIngredients) {
      recipeIngredients.forEach((item) => {
        const currentItemCat = item.option["USDA_Cat"];
        if (tempObjCategoryTotals[currentItemCat]) {
          tempObjCategoryTotals[currentItemCat] += parseFloat(item.serving);
        } else {
          tempObjCategoryTotals[currentItemCat] = parseFloat(item.serving);
        }
      });
      setCategoryTotals(tempObjCategoryTotals);
    }
  }, [recipeIngredients]);

  const calcGroupTotal = (item) => {
    let groupTotal = 0;

    if (item["sub_groups"]) {
      if (item["type"] === "limits on calories for other uses") {
        groupTotal = "See food label below";
      } else {
        item["sub_groups"].forEach((sub) => {
          if (categoryTotals[sub["sub_type"]]) {
            groupTotal += parseFloat(categoryTotals[sub["sub_type"]]);
          }
        });
      }
    } else if (!item["sub_groups"] && categoryTotals[item["type"]]) {
      if (item["type"] === "oils") {
        groupTotal = parseFloat(categoryTotals[item["type"]]) * 28.35;
      } else {
        groupTotal = categoryTotals[item["type"]];
      }
    }
    return groupTotal;
  };

  const calcSubGroupTotal = (sub) => {
    let subGroupTotal = 0;
    if (sub["sub_type"] === "in percent calories") {
      subGroupTotal = "See food label below";
    } else if (categoryTotals[sub["sub_type"]]) {
      subGroupTotal += categoryTotals[sub["sub_type"]];
    }
    return subGroupTotal;
  };

  const getUnit = (item) => {
    const typesCup = ["vegetables", "fruits", "dairy"];
    const typesOz = ["grains", "protein foods"];
    const typesGrams = ["oils"];

    if (item["type"] && item["type"] !== "limits on calories for other uses") {
      if (typesCup.includes(item["type"])) {
        return "cup";
      } else if (typesOz.includes(item["type"])) {
        return "ounce";
      } else if (typesGrams.includes(item["type"])) {
        return "grams";
      }
    } else {
      return "";
    }
  };

  return (
    <div className="usda-table-wrapper">
      <div className="usda-row-wrapper">
        <div className="usda-table-title usda-table-blue-bkgd-color">
          Food Group or Subgroup
        </div>
        <div className="usda-table-title">USDA Recommendation</div>
        <div className="usda-table-title">Recipe Total</div>
      </div>
      {appContext.usdaGuidelinesData.map((item) => {
        return (
          <>
            <div className="usda-row-wrapper">
              <div className="usda-table-group">{item["type"]}</div>
              <div className="usda-table-amount">
                {item["amount"] + " " + item["unit"] + " / " + item["time"]}
              </div>
              <div className="usda-table-amount">
                {categoryTotals && calcGroupTotal(item) + " " + getUnit(item)}
              </div>
            </div>
            {item["sub_groups"] &&
              item["sub_groups"].map((sub) => {
                return (
                  <div className="usda-row-wrapper">
                    <div className="usda-table-sub-group">
                      {sub["sub_type"]}
                    </div>
                    <div className="usda-table-sub-amount">
                      {sub["sub_amount"] +
                        " " +
                        sub["sub_unit"] +
                        " / " +
                        sub["sub_time"]}
                    </div>
                    <div className="usda-table-sub-amount">
                      {categoryTotals &&
                        calcSubGroupTotal(sub) + " " + getUnit(item)}
                    </div>
                  </div>
                );
              })}
          </>
        );
      })}
    </div>
  );
};

export default USDAMain;
