import React, { useEffect, useState, useLayoutEffect } from "react";
import { BarChart, Bar, YAxis, Tooltip } from "recharts";
import CustomTooltip from "./CustomTooltip";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function FoodBarChart(props) {
  const { microNutrients } = props;
  const [width, height] = useWindowSize();

  return (
    <BarChart
      width={width > 700 ? 640 : 300}
      height={150}
      data={microNutrients}
    >
      {<Bar dataKey="value" fill={"#8884d8"} />}
      <YAxis
        type="number"
        domain={[0, 100]}
        style={{
          fontSize: "12px",
          fontFamily: "Arial",
          width: "20px",
        }}
      />
      <Tooltip content={<CustomTooltip type="bar" />} />
    </BarChart>
  );
}
