import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../components/Main/CodeBlock";
import AppContext from "../../context/AppContext";
import Nutrition from "./Nutrition";
import AmazonExamples from "./AmazonExamples";
import Sidebar from "./Sidebar";
import RecipeForm from "./RecipeForm";
import RecipeAndLabel from "./RecipeAndLabel";
import IngredientsForm from "./IngredientsForm";
import NewsletterForm from "../Auth/NewsletterForm";
import BzMain from "./BlueZones/BzMain";
import BzCalculator from "./BlueZones/BzCalculator";
import HNTDCalculator from "./HNTD/HNTDCalculator";
import TBCalculator from "./TomBrady/TBCalculator";
import TBMain from "./TomBrady/TBMain";
import { calculateRecipeNutritionTotals } from "../../lib/functions";
import Loader from "../Auth/Loader";
import USDAMain from "./USDA/USDAMain";
import NutrientDenseOptions from "./USDA/NutrientDenseOptions";

const CalorieCalculator = () => {
  const appContext = useContext(AppContext);
  const [calculatedRecipeObj, setcalculatedRecipeObj] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [unselectedOptions, setUnselectedOptions] = useState();

  // const [foodCategories, setFoodCategories] = useState([]);
  const [formData, setFormData] = useState();

  const [recipeIngredients, setRecipeIngredients] = useState();

  const [blogData, setBlogData] = useState();
  const [blueZoneBlogData, setBlueZoneBlogData] = useState();
  const [usdaDietGuideBlogData, setUsdaDietGuideBlogData] = useState();
  const [dietaryPatternBlogData, setDietaryPatternBlogData] = useState();
  const [foodLabelBlogData, setFoodLabelBlogData] = useState();
  const [hntdBlogData, setHNTDBlogData] = useState();
  const [tbBlogData, setTBBlogData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateFoodCategoryTotals = () => {
    if (recipeIngredients) {
      let copyFoodCategories = {};

      Object.keys(appContext.foodCategories).forEach((item) => {
        copyFoodCategories[item] = 0;
      });

      recipeIngredients.forEach((item) => {
        copyFoodCategories[item.option["Type"]] += parseFloat(item.serving);
        appContext.setFoodCategories(copyFoodCategories);
      });
    }
  };

  const addIngredientsToRecipeForm = () => {
    if (recipeIngredients) {
      let newObj = {};
      recipeIngredients.forEach((item) => {
        const ingName = item.option["Name"];
        newObj[ingName] = item.serving.toString();
      });
      setFormData({ ...formData, ingredients: newObj });
    }
  };

  useEffect(() => {
    if (recipeIngredients) {
      calculateRecipeNutritionTotals(recipeIngredients, setcalculatedRecipeObj);
      calculateFoodCategoryTotals(
        recipeIngredients,
        appContext.setFoodCategories,
        appContext.foodCategories
      );
      addIngredientsToRecipeForm();
    }
  }, [recipeIngredients]);

  useEffect(() => {
    if (appContext.mongoNutrIngr) {
      setSelectedOption(appContext.mongoNutrIngr[0]);
      setUnselectedOptions(appContext.mongoNutrIngr);

      const typesSet = new Set(
        appContext.mongoNutrIngr.map((item) => item["Type"])
      );
      const typesArray = Array.from(typesSet);
      const typesObj = {};
      typesArray.forEach((item) => {
        typesObj[item] = 0;
      });

      appContext.setFoodCategories(typesObj);

      const typesSetUSDA = new Set(
        appContext.mongoNutrIngr.map((item) => item["USDA_Cat"])
      );
      const typesArrayUSDA = Array.from(typesSetUSDA);
      const typesObjUSDA = {};
      typesArrayUSDA.forEach((item) => {
        typesObjUSDA[item] = 0;
      });
      appContext.setUsdaFoodCategories(typesObjUSDA);
    }
  }, [appContext.mongoNutrIngr]);

  const handleDeleteIng = (e) => {
    const parsedId = JSON.parse(e.target.id);
    const newArray = recipeIngredients.filter((item) => {
      return item.option["Name"] !== e.target.title;
    });
    if (newArray.length) {
      setRecipeIngredients(newArray);
    } else {
      setRecipeIngredients();
    }

    setUnselectedOptions((prevState) => {
      return [...prevState, parsedId.option];
    });
  };

  const getFileContents = (file, lessonType, setFunc) => {
    fetch(appContext.URL_2 + `/get-lesson${lessonType}/${file}/`)
      .then((response) => response.json())
      .then((data) => {
        setFunc(data);
      });
  };

  useEffect(() => {
    getFileContents(
      "calorie_calculator.md",
      "/calorie_calculator",
      setBlogData
    );

    getFileContents(
      "usda_guidelines.md",
      "/calorie_calculator",
      setUsdaDietGuideBlogData
    );

    getFileContents(
      "dietary_pattern.md",
      "/calorie_calculator",
      setDietaryPatternBlogData
    );

    getFileContents(
      "food_label.md",
      "/calorie_calculator",
      setFoodLabelBlogData
    );

    getFileContents(
      "blue_zones.md",
      "/calorie_calculator",
      setBlueZoneBlogData
    );

    getFileContents(
      "how_not_to_die.md",
      "/calorie_calculator",
      setHNTDBlogData
    );

    getFileContents("tom_brady.md", "/calorie_calculator", setTBBlogData);
  }, []);

  // useEffect(() => {
  //   console.log(dietaryPatternBlogData);
  // }, [dietaryPatternBlogData]);

  return (
    <>
      {(!blogData ||
        !usdaDietGuideBlogData ||
        !dietaryPatternBlogData ||
        !foodLabelBlogData ||
        !blueZoneBlogData ||
        !hntdBlogData ||
        !tbBlogData) && <Loader />}
      {blogData &&
        usdaDietGuideBlogData &&
        dietaryPatternBlogData &&
        foodLabelBlogData &&
        blueZoneBlogData &&
        hntdBlogData &&
        tbBlogData && (
          <>
            <div className="sections-wrapper">
              <Sidebar
                topic="calorie-calculator"
                recipeIng={recipeIngredients}
                blogData={blogData}
                usdaDietGuideBlogData={usdaDietGuideBlogData}
                dietaryPatternBlogData={dietaryPatternBlogData}
                foodLabelBlogData={foodLabelBlogData}
                blueZoneBlogData={blueZoneBlogData}
                hntdBlogData={hntdBlogData}
                tbBlogData={tbBlogData}
              />
            </div>

            <div className="lesson-wrapper">
              {blogData && (
                <ReactMarkdown
                  source={blogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}

              <IngredientsForm
                recipeIngredients={recipeIngredients}
                setRecipeIngredients={setRecipeIngredients}
                usdaFoodCategories={appContext.usdaFoodCategories}
                foodCategories={appContext.foodCategories}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                unselectedOptions={unselectedOptions}
                setUnselectedOptions={setUnselectedOptions}
              />

              {recipeIngredients && (
                <RecipeAndLabel
                  recipeIngredients={recipeIngredients}
                  handleDeleteIng={handleDeleteIng}
                  foodCategories={appContext.foodCategories}
                />
              )}

              <div className="calorie-calculator-selector-wrapper">
                <a
                  href={`#see-results`}
                  className="calorie-calculator-arrow-wrapper"
                  id={`see-results`}
                >
                  <h1 className="calorie-calculator-selector-wrapper">
                    See Results
                  </h1>
                </a>
              </div>

              <div className="calorie-calculator-selector-wrapper">
                <h2 className="calorie-calculator-selector-wrapper">
                  Compare Your Recipe To USDA Recommendations
                </h2>
              </div>

              {appContext.usdaGuidelinesData && (
                <USDAMain recipeIngredients={recipeIngredients} />
              )}

              <div className="calorie-calculator-selector-wrapper">
                <h2 className="calorie-calculator-selector-wrapper">
                  View Your Food Label
                </h2>
              </div>

              {!recipeIngredients && <h3>Add Ingredients To See Food Label</h3>}
              {recipeIngredients && (
                <RecipeAndLabel
                  recipeIngredients={recipeIngredients}
                  handleDeleteIng={handleDeleteIng}
                  foodCategories={appContext.foodCategories}
                />
              )}

              {recipeIngredients && calculatedRecipeObj && (
                <Nutrition
                  searchTerms={""}
                  calculatedRecipeObj={calculatedRecipeObj}
                  // theRecipe={{
                  //   nutrition: recipeIngredients,
                  //   num_served:
                  //     formData && formData.num_served ? formData.num_served : 1,
                  // }}
                  theRecipeIngredients={recipeIngredients}
                  theRecipeNumServed={
                    formData && formData.num_served ? formData.num_served : 1
                  }
                />
              )}

              {usdaDietGuideBlogData && (
                <ReactMarkdown
                  source={usdaDietGuideBlogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}

              {/* <div className="calorie-calculator-selector-wrapper">
                <h2>View your recipe and food label</h2>
              </div>
              <div className="calorie-calculator-arrow">
                <div className="left-arrow"></div>
                <div className="right-arrow"></div>
              </div> */}

              {/* <div className="calorie-calculator-selector-wrapper">
          <AmazonExamples topic={`food_calculator`} />
        </div> */}
              {/* 
              <div className="calorie-calculator-selector-wrapper">
                <a
                  href={`#usda-healthy-dietary-pattern`}
                  className="calorie-calculator-arrow-wrapper"
                  id={`usda-healthy-dietary-pattern`}
                >
                  <h1 className="calorie-calculator-selector-wrapper">
                    USDA Healthy Dietary Pattern
                  </h1>
                </a>
              </div> */}

              {dietaryPatternBlogData && (
                <ReactMarkdown
                  source={dietaryPatternBlogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}

              <div className="calorie-calculator-selector-wrapper">
                {appContext.usdaGuidelinesData && (
                  <USDAMain recipeIngredients={recipeIngredients} />
                )}
              </div>

              {foodLabelBlogData && (
                <ReactMarkdown
                  source={foodLabelBlogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}

              {!recipeIngredients && <h3>Add Ingredients To See Food Label</h3>}
              {recipeIngredients && (
                <>
                  <RecipeAndLabel
                    recipeIngredients={recipeIngredients}
                    handleDeleteIng={handleDeleteIng}
                    foodCategories={appContext.foodCategories}
                  />
                </>
              )}

              {recipeIngredients && calculatedRecipeObj && (
                <Nutrition
                  searchTerms={""}
                  calculatedRecipeObj={calculatedRecipeObj}
                  // theRecipe={{
                  //   nutrition: recipeIngredients,
                  //   num_served:
                  //     formData && formData.num_served ? formData.num_served : 1,
                  // }}
                  theRecipeIngredients={recipeIngredients}
                  theRecipeNumServed={
                    formData && formData.num_served ? formData.num_served : 1
                  }
                />
              )}

              <div className="calorie-calculator-selector-wrapper">
                <a
                  href={`#usda-nutrient-dense-choices`}
                  className="calorie-calculator-arrow-wrapper"
                  id={`usda-nutrient-dense-choices`}
                >
                  <h1 className="calorie-calculator-selector-wrapper">
                    USDA Nutrient Dense Choices
                  </h1>
                </a>
              </div>

              <div className="calorie-calculator-selector-wrapper">
                <NutrientDenseOptions />
              </div>

              {blueZoneBlogData && (
                <ReactMarkdown
                  source={blueZoneBlogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}
              <BzMain />
              <BzCalculator
                recipeIngredients={recipeIngredients}
                calculatedRecipeObj={calculatedRecipeObj}
              />

              {hntdBlogData && (
                <ReactMarkdown
                  source={hntdBlogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}

              <HNTDCalculator
                recipeIngredients={recipeIngredients}
                // foodCategories={appContext.foodCategories}
              />

              {tbBlogData && (
                <ReactMarkdown
                  source={tbBlogData}
                  renderers={{ code: CodeBlock }}
                  className="lesson-wrapper-col"
                />
              )}

              <TBMain />

              <TBCalculator
                recipeIngredients={recipeIngredients}
                calculatedRecipeObj={calculatedRecipeObj}
              />

              <RecipeForm
                recipeIngredients={recipeIngredients}
                handleDeleteIng={handleDeleteIng}
                foodCategories={appContext.foodCategories}
                formData={formData}
                setFormData={setFormData}
              />
              <NewsletterForm />
              <div className="calorie-calculator-selector-wrapper">
                <a href="#view-recipes" id="view-recipes">
                  <h1 className="">View Recipes</h1>
                </a>
              </div>
              <h2>Coming soon . . .</h2>
            </div>
          </>
        )}
    </>
  );
};

export default CalorieCalculator;
