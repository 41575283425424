import React from "react";

const Zone = (props) => {
  const { zone } = props;

  return (
    <div className="zone-card">
      <h2 className="">{zone["city"]}</h2>
      <iframe src={zone["map"]} className="zone-map" loading="lazy" />
      <h3 className="zone-title">Typical Daily Diet</h3>
      <div className="zone-list">
        {Object.keys(zone["typical_daily_diet"]).map((item) => {
          return (
            <div className="zone-list-item">
              {item.replace(/_/g, " ") +
                ": " +
                zone["typical_daily_diet"][item]}
            </div>
          );
        })}
      </div>
      <h3 className="zone-title">Longevity Ingredients</h3>
      <div className="zone-list">
        {Object.keys(zone["ingredients"]).map((item) => {
          return (
            <div className="zone-list-item">{item.replace(/_/g, " ")}</div>
          );
        })}
      </div>
      <h3 className="zone-title">Notes about the {zone["city"]}ns</h3>
      <div className="zone-list-notes-wrapper">
        {Object.keys(zone["notes"]).map((item) => {
          return (
            <div className="zone-list-notes">{"*  " + zone["notes"][item]}</div>
          );
        })}
      </div>
    </div>
  );
};

export default Zone;
