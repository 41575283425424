import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import FoodPieChart from "../FoodPieChart";
import IngCard from "../Lib/IngCard";

const TBCalculator = (props) => {
  const { recipeIngredients, calculatedRecipeObj } = props;
  const appContext = useContext(AppContext);
  const [eighty20Obj, setEighty20Obj] = useState();
  const [eighty20Num, setEighty20Num] = useState();
  const [tbListsObj, setTbListsObj] = useState();
  const tbEighty20Num = [
    { name: "vegetables, beans, grains", value: 80 },
    { name: "lean protein", value: 20 },
  ];

  //   useEffect(() => {
  //     fetch(appContext.URL_2 + `/get-tom-brady/`)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setappContext.TBInfo(data[0]);
  //       });
  //   }, []);

  const calculateEightyTwenty = () => {
    let eightyTwentyObj = {
      vegetables: [],
      whole_grains: [],
      beans: [],
      lean_protein: [],
      other: [],
    };

    let eightyTwentyGramsObj = {
      vegetables: 0,
      whole_grains: 0,
      beans: 0,
      lean_protein: 0,
      other: 0,
    };

    recipeIngredients.forEach((item) => {
      if (
        item.option["Type"] === "cruciferous" ||
        item.option["Type"] === "greens" ||
        item.option["Type"] === "vegetables" ||
        item.option["Type"] === "spices"
      ) {
        if (
          !Object.keys(appContext.tbInfo["excluded"]).includes(
            item.option["Name"].replace(/ /g, "_")
          )
        ) {
          eightyTwentyObj["vegetables"].push(item);
          eightyTwentyGramsObj["vegetables"] +=
            parseFloat(item.option["Grams_1"]) * parseFloat(item.serving);
        }
      } else if (item.option["Type"] === "whole grains") {
        eightyTwentyObj["whole_grains"].push(item);
        eightyTwentyGramsObj["whole_grains"] +=
          parseFloat(item.option["Grams_1"]) * parseFloat(item.serving);
      } else if (item.option["Type"] === "beans") {
        eightyTwentyObj["beans"].push(item);
        eightyTwentyGramsObj["beans"] +=
          parseFloat(item.option["Grams_1"]) * parseFloat(item.serving);
      } else if (
        item.option["Type"] === "meat" ||
        item.option["Type"] === "fish"
      ) {
        if (
          !Object.keys(appContext.tbInfo["excluded"]).includes(
            item.option["Name"].replace(/ /g, "_")
          )
        ) {
          eightyTwentyObj["lean_protein"].push(item);
          eightyTwentyGramsObj["lean_protein"] +=
            parseFloat(item.option["Grams_1"]) * parseFloat(item.serving);
        }
      } else {
        eightyTwentyObj["other"].push(item);
        eightyTwentyGramsObj["other"] +=
          parseFloat(item.option["Grams_1"]) * parseFloat(item.serving);
      }
    });
    setEighty20Obj(eightyTwentyObj);
    if (calculatedRecipeObj) {
      const eightyTwentyGramsArray = Object.keys(eightyTwentyGramsObj).map(
        (item) => {
          return {
            name: item,
            value:
              (parseFloat(eightyTwentyGramsObj[item]) /
                parseFloat(calculatedRecipeObj["Grams_1"])) *
              100,
          };
        }
      );
      setEighty20Num(eightyTwentyGramsArray);
    }
  };

  const calculateLists = () => {
    const newObj = {
      in: [],
      excluded: [],
      neither: [],
    };
    recipeIngredients.forEach((item) => {
      if (
        Object.keys(appContext.tbInfo.ingredients).includes(
          item.option["Name"].replace(/ /g, "_")
        )
      ) {
        newObj["in"].push(item);
      } else if (
        Object.keys(appContext.tbInfo.excluded).includes(
          item.option["Name"].replace(/ /g, "_")
        )
      ) {
        newObj["excluded"].push(item);
      } else {
        newObj["neither"].push(item);
      }
    });

    setTbListsObj(newObj);
  };

  useEffect(() => {
    if (recipeIngredients && appContext.tbInfo) {
      calculateEightyTwenty();
      calculateLists();
    }
  }, [recipeIngredients, appContext.tbInfo, calculatedRecipeObj]);

  return (
    <div className="lesson-wrapper-center">
      <div className="calorie-calculator-selector-wrapper">
        <a href="#tom-brady-calculator" id="tom-brady-calculator">
          <h1 className="">Tom Brady Calculator</h1>
        </a>
      </div>
      <h2 className="calorie-calculator-selector-wrapper">
        Discover how the recipe compares to Tom Brady's diet
      </h2>
      {(!recipeIngredients || !appContext.foodCategories) && (
        <h2>Add Ingredients To Your Recipe</h2>
      )}
      <div className="tb-info-data-wrapper">
        {recipeIngredients && appContext.tbInfo && eighty20Num && (
          <div className="tb-calculator-items-wrapper">
            <div className="tb-calculator-item">
              <h3 className="tb-calculator-item-title">
                Tom Brady's 80 / 20 Rule
              </h3>
              <FoodPieChart macroNutrients={tbEighty20Num} />
            </div>
            <div className="tb-calculator-item">
              <h3 className="tb-calculator-item-title">
                The Recipe's 80 / 20 Score
              </h3>
              <FoodPieChart macroNutrients={eighty20Num} />
            </div>
          </div>
        )}

        {tbListsObj && tbListsObj["in"] && (
          <>
            <h3>
              The recipe has{" "}
              <span className="hntd-bold">
                {tbListsObj["in"].length} ingredients
              </span>{" "}
              in Tom Brady's diet
            </h3>
            <div className="calorie-calculator-items-wrapper">
              <div className="hntd-selected-categories">
                {tbListsObj["in"].map((item) => {
                  return (
                    <IngCard
                      name={item.option["Name"]}
                      serving={item.serving}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}

        {tbListsObj && tbListsObj["excluded"] && (
          <>
            <h3>
              The recipe has{" "}
              <span className="hntd-bold">
                {tbListsObj["excluded"].length} ingredients
              </span>{" "}
              that Tom Brady <span className="hntd-bold">avoids</span>
            </h3>
            <div className="calorie-calculator-items-wrapper">
              <div className="hntd-selected-categories">
                {tbListsObj["excluded"].map((item) => {
                  return (
                    <IngCard
                      name={item.option["Name"]}
                      serving={item.serving}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}

        {tbListsObj && tbListsObj["neither"] && (
          <>
            <h3>
              The recipe has{" "}
              <span className="hntd-bold">
                {tbListsObj["neither"].length} other
              </span>{" "}
              ingredients
            </h3>
            <div className="calorie-calculator-items-wrapper">
              <div className="hntd-selected-categories">
                {tbListsObj["neither"].map((item) => {
                  return (
                    <IngCard
                      name={item.option["Name"]}
                      serving={item.serving}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>

      {/* {bzResult && calculatedRecipeObj && (
        <div className="all-zones-wrapper">
          <h3>The recipe has:</h3>
          {Object.keys(bzResult).map((item) => {
            let totalPercent = 0;
            return (
              <div className="zone-wrapper">
                <span className="zone-bold">{bzResult[item].length} </span>
                {bzResult[item].length === 1
                  ? "ingredient"
                  : "ingredients"}{" "}
                from the <span className="zone-bold">{item} </span>
                diet and based on serving sizes in grams is{" "}
                {bzResult[item].map((ing) => {
                  totalPercent +=
                    ((parseFloat(ing.option["Grams_1"]) *
                      parseFloat(ing.serving)) /
                      calculatedRecipeObj["Grams_1"]) *
                    100;
                  return (
                    <>
                      {(
                        ((parseFloat(ing.option["Grams_1"]) *
                          parseFloat(ing.serving)) /
                          calculatedRecipeObj["Grams_1"]) *
                        100
                      ).toFixed(1)}
                      % {ing.option["Name"]}
                      {bzResult[item].length === 1 ? " " : ", "}
                    </>
                  );
                })}
                {bzResult[item].length === 0 ? " " : "and  "}{" "}
                <span className="zone-bold">
                  {totalPercent.toFixed(1)}% {item}n{" "}
                </span>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default TBCalculator;
