// import "./styles.css";
import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import CustomTooltip from "./CustomTooltip";

const COLORS = [
  "#311475",
  "#4b2a97",
  "#433bd6",
  "#6963d8",
  "#8884d8",
  "#8361c2",
  "#a084d6",
  "#c3b0eb",
  "#e6dcfd",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function FoodPieChart(props) {
  const { macroNutrients } = props;
  return (
    <PieChart width={180} height={180}>
      <Pie
        data={macroNutrients}
        cx={90}
        cy={90}
        labelLine={false}
        // label={renderCustomizedLabel}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {macroNutrients.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} type="pie" />
      {/* <Legend
        payload={macroNutrients.map((item, index) => ({
          id: item.name,
          type: "square",
          value: `${item.name} (${item.value}%)`,
          color: COLORS[index % COLORS.length],
        }))}
      /> */}
    </PieChart>
  );
}
