import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import TBZone from "./TBZone";

const TBMain = () => {
  const appContext = useContext(AppContext);

  // useEffect(() => {
  //   if (appContext.zones) {
  //     console.log(appContext.zones);
  //   }
  // }, [appContext.zones]);

  return (
    <div className="lesson-wrapper">
      {appContext.tbInfo && (
        <div className="zone-card">
          {/* <h2 className="">{appContext.tbInfo["city"]}</h2> */}
          {/* <iframe src={appContext.tbInfo["map"]} className="zone-map" loading="lazy" /> */}
          <h3 className="zone-title">Main Categories He Eats From</h3>
          <div className="zone-list">
            {Object.keys(appContext.tbInfo["categories"]).map((item) => {
              return (
                <div className="zone-list-item">{item.replace(/_/g, " ")}</div>
              );
            })}
          </div>
          <h3 className="zone-title">Main Ingredients He Eats</h3>
          <div className="zone-list">
            {Object.keys(appContext.tbInfo["ingredients"]).map((item) => {
              return (
                <div className="zone-list-item">{item.replace(/_/g, " ")}</div>
              );
            })}
          </div>
          <h3 className="zone-title">Foods He Avoids</h3>
          <div className="zone-list">
            {Object.keys(appContext.tbInfo["excluded"]).map((item) => {
              return (
                <div className="zone-list-item">{item.replace(/_/g, " ")}</div>
              );
            })}
          </div>
          <h3 className="zone-title">Notes about his diet</h3>
          <div className="zone-list-notes-wrapper">
            {Object.keys(appContext.tbInfo["notes"]).map((item) => {
              return (
                <div className="zone-list-notes">
                  {"*  " + appContext.tbInfo["notes"][item]}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TBMain;
