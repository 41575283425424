import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import AppContext from "../../context/AppContext";
import Loader from "./Loader";
import Alert from "./Alert";
import { validateEmail, validateURL, validateDesc } from "../../lib/functions";

const RecipeApply = () => {
  const appContext = useContext(AppContext);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [redirect, setRedirect] = useState();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isValidDesc, setIsValidDesc] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  const recaptchaRef = React.createRef();

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleApplyNow = () => {
    setIsLoading(true);
    fetch(`${appContext.URL_2}/recipe-apply`, {
      method: "POST",
      body: JSON.stringify({
        formData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setShowAlert(data);
        setCaptcha(false);
        setTimeout(() => {
          setShowAlert(false);
          setRedirect("/");
        }, [2000]);
      });
  };

  useEffect(() => {
    if (formData && formData.email) {
      const emailValidity = validateEmail(formData.email);
      setIsValidEmail(emailValidity);
    }

    if (formData && formData.link) {
      const urlValidity = validateURL(formData.link);
      setIsValidUrl(urlValidity);
    }

    if (formData && formData.description) {
      const descValidity = validateDesc(formData.description);
      setIsValidDesc(descValidity);
    }
  }, [formData]);

  function handleCaptcha(value) {
    if (value) {
      setCaptcha(value);
    }
  }

  return (
    <div className="lesson-wrapper">
      {redirect && <Redirect to={redirect} />}
      {showAlert && <Alert alert={showAlert} />}

      <h1 className="margin-bottom-40">Post Recipes Online</h1>
      <h2 className="margin-bottom-40">
        Do you promote healthy eating online? Do you want to help others eat
        healthy?
      </h2>

      <h3 className="margin-bottom-40">
        We are looking for committed food bloggers or social media influencers
        with an existing online presence.
      </h3>

      <h2 className="margin-bottom-40">
        Apply below to join us in posting recipes to help people eat smarter.
      </h2>

      <h1 className="margin-bottom-40">Free Recipe Analytics</h1>

      <h3 className="margin-bottom-40">
        You post recipes. We give you free analytical tools that help better
        understand the recipes.
      </h3>

      <h3 className="margin-bottom-40">
        It is free to apply and join. Plus, you get a unique analytical look at
        your recipes, the chance for more exposure, and a community of
        like-minded people.
      </h3>
      <h3 className="margin-bottom-40">
        Please include a link and brief description of your online food blogs
        and posts. We will review soon and respond by email.
      </h3>

      <div className="calorie-calculator-items-wrapper">
        {redirect && <Redirect to={redirect} />}

        <div className="recipe-form-wrapper">
          <div className="recipe-form">
            <div className="apply-input-wrapper">
              <input
                name="email"
                type="email"
                placeholder="Email"
                className="calculator-input apply-form-input"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidEmail}`}
              >
                {isValidEmail ? "✓" : "Valid email required"}
              </div>
            </div>

            <div className="apply-input-wrapper">
              <input
                name="link"
                type="text"
                placeholder="Link"
                className="calculator-input apply-form-input"
                onChange={handleInputChange}
              />
              <div
                className={`apply-form-label apply-form-label-${isValidUrl}`}
              >
                {isValidUrl ? "✓" : "Valid https:// url required"}
              </div>
            </div>
          </div>

          <div className="apply-input-wrapper">
            <textarea
              name="description"
              type="text"
              placeholder="Briefly describe food blogs or posts"
              className="calculator-input apply-form-input-desc"
              onChange={handleInputChange}
            />
            <div className={`apply-form-label apply-form-label-${isValidDesc}`}>
              {isValidDesc ? "✓" : "Must be 10-300 characters"}
            </div>
          </div>

          {isLoading && (
            <div className={`to-form-button to-form-button-true`}>
              <Loader />
            </div>
          )}
          {!isLoading && (
            <>
              <button
                disabled={
                  !(
                    captcha &&
                    formData &&
                    formData.email &&
                    isValidEmail &&
                    formData.link &&
                    isValidUrl &&
                    formData.description &&
                    isValidDesc
                  )
                }
                className={`to-form-button to-form-button-${
                  captcha &&
                  formData &&
                  formData.email &&
                  formData.link &&
                  formData.description
                }`}
                onClick={handleApplyNow}
              >
                Apply Now
              </button>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT}
                onChange={handleCaptcha}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeApply;
